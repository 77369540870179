import {DifficultySummaries, PlantDto} from '@hconnect/common/types'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'

const QueryKey = 'difficulty-summaries'

export const usePlantDifficultySummaries = (
  plantId: string,
  options?: UseQueryOptions<DifficultySummaries, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<DifficultySummaries, AxiosError>(
    [QueryKey, plantId],
    async () => {
      const response = await axiosInstance.get<PlantDto>(`/pom-questionnaires/${plantId}`)

      return response.data.scoring.difficultySummaries
    },
    {
      staleTime: 1000 * 60 * 15, // 15 mins
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
