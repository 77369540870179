import {TASKS_ASSIGNED_TO_ME_PARAM_KEY} from '@hconnect/common/hproduce/consts'

export const TasksQuickFilterKeys = {
  ASSIGNED_TO_ME: TASKS_ASSIGNED_TO_ME_PARAM_KEY,
  SOURCES: 'sources'
} as const

export const TasksSortOptionsKeys = {
  SORT_BY: 'sortBy',
  SORT_ORDER: 'sortOrder'
} as const

export const TasksFilterKey = {
  TITLE: 'title',
  DUE_DATE_RANGE: 'dueDateRange',
  STATUSES: 'statuses',
  ASSIGNEE: 'assignee',
  CATEGORIES: 'categories',
  PRIORITIES: 'priorities',
  PLANT: 'plant',
  PROCESS_STAGES: 'processStages',
  EQUIPMENT: 'equipment',
  MAIN_EQUIPMENT: 'mainEquipment',
  POM_ACTION_SOURCES: 'pomActionSources',
  POM_QUESTION_CATEGORIES: 'pomQuestionCategories',
  POM_SECTIONS: 'pomSections',
  POM_TOPICS: 'pomTopics',
  POM_QUESTIONS: 'pomQuestions'
} as const

export type TasksFilterKeyName = (typeof TasksFilterKey)[keyof typeof TasksFilterKey]

export const TasksPageParamKey = {
  ...TasksFilterKey,
  ...TasksQuickFilterKeys,
  ...TasksSortOptionsKeys
} as const
