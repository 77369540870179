import type {User} from '@hconnect/common/types'
import {useQuery, type UseQueryOptions} from '@tanstack/react-query'
import {type AxiosError} from 'axios'
import {useNavigate} from 'react-router'

import {ApiPath} from '../../../shared/consts'
import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'

const QueryKey = 'user'
const CACHE_TIME_IN_MS = 1000 * 60

export const useUserById = (
  id: string,
  options?: UseQueryOptions<User | undefined, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<User | undefined, AxiosError>({
    queryKey: [QueryKey, id],
    queryFn: async () => {
      const path = `${ApiPath.USERS}/${id}`
      const {data} = await axiosInstance.get<User>(path)

      return data
    },
    onError: (err) => handleError(err, navigate),
    cacheTime: CACHE_TIME_IN_MS,
    refetchOnWindowFocus: false,
    ...options
  })
}
