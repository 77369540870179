import type {User} from '@hconnect/common/types'
import {useQuery, type UseQueryOptions} from '@tanstack/react-query'
import {type AxiosError} from 'axios'
import {useNavigate} from 'react-router'

import {ApiPath} from '../../../shared/consts'
import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'

const QueryKey = 'assignee-search'
const CACHE_TIME_IN_MS = 1000 * 60

export const useAssigneeSearch = (
  namePattern: string | undefined,
  options?: UseQueryOptions<User[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<User[], AxiosError>({
    queryKey: [QueryKey, namePattern],
    queryFn: async () => {
      const path = `${ApiPath.TASKS}/assignees`

      if (!namePattern) {
        return Promise.resolve([])
      }
      const response = await axiosInstance.get<User[]>(path, {params: {namePattern}})
      return response.data
    },
    onError: (err) => handleError(err, navigate),
    cacheTime: CACHE_TIME_IN_MS,
    refetchOnWindowFocus: false,
    ...options
  })
}
