import {Iso8601} from '@hconnect/uikit'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError, AxiosInstance} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {PERFORMANCE_KPI_REQUEST_STALE_TIME} from '../../consts'
import {formatDateUtcToTimezoneDateOnly} from '../../helpers/dateFormat.helpers'
import {
  QuarterlyForecastTechnicalCementPowerConsumptionsHierarchy,
  TechnicalCementPowerConsumptionResponse,
  MtmYtmRequestPayload
} from '../../types'

export const getTechnicalCementPowerConsumptionMtmYtmUrlSuffix = (showYTM?: boolean) =>
  showYTM ? 'technical-cement-power-consumption-ytm' : 'technical-cement-power-consumption-mtm'

export const getTechnicalCementPowerConsumptionMtmYtmQueryKeys = (
  requestPayload: MtmYtmRequestPayload
) => [
  getTechnicalCementPowerConsumptionMtmYtmUrlSuffix(requestPayload.showYTM),
  formatDateUtcToTimezoneDateOnly(requestPayload.timestamp, requestPayload.timezone),
  requestPayload.locationId
]

export const getTechnicalCementPowerConsumption = async <T>(
  axiosInstance: AxiosInstance,
  {timestamp, timezone, locationId, showYTM}: MtmYtmRequestPayload
) => {
  const referenceDate = formatDateUtcToTimezoneDateOnly(timestamp, timezone)
  const url = `/kpi/dashboard/${getTechnicalCementPowerConsumptionMtmYtmUrlSuffix(showYTM)}`

  const response = await axiosInstance.get<T>(url, {params: {locationId, date: referenceDate}})

  return response.data
}

export const useTechnicalCementPowerConsumption = <T>(
  payload: MtmYtmRequestPayload,
  options?: UseQueryOptions<TechnicalCementPowerConsumptionResponse, AxiosError, T>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const QueryKeys = getTechnicalCementPowerConsumptionMtmYtmQueryKeys(payload)

  return useQuery<TechnicalCementPowerConsumptionResponse, AxiosError, T>(
    QueryKeys,
    async () => {
      return getTechnicalCementPowerConsumption(axiosInstance, payload)
    },
    {
      onError: (err) => handleError(err, navigate),
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      ...options
    }
  )
}

export const useTechnicalCementPowerConsumptionsQuarterly = (
  timestamp: Iso8601,
  timezone: string,
  locationId?: string,
  options?: UseQueryOptions<
    QuarterlyForecastTechnicalCementPowerConsumptionsHierarchy | null,
    AxiosError
  >
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const referenceDate = formatDateUtcToTimezoneDateOnly(timestamp, timezone)

  return useQuery<QuarterlyForecastTechnicalCementPowerConsumptionsHierarchy | null, AxiosError>(
    ['technical-cement-power-consumption-quarterly', referenceDate, locationId],
    async () => {
      if (!locationId) return Promise.resolve(null)

      const response =
        await axiosInstance.get<QuarterlyForecastTechnicalCementPowerConsumptionsHierarchy>(
          '/kpi/dashboard/technical-cement-power-consumption-qf',
          {
            params: {locationId, timestamp: referenceDate}
          }
        )
      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      ...options
    }
  )
}
