import {mergeSx} from '@hconnect/uikit/src/lib2'
import {ButtonProps, CircularProgress, SxProps, Theme} from '@mui/material'
import {isArray, isEmpty, isObject} from 'lodash'
import React from 'react'

import {ErrorPlaceholder} from './ErrorPlaceholder'
import {NoDataPlaceholder} from './NoDataPlaceholder'
import {PlaceholderWrapper} from './PlaceholderWrapper'

export type DataContentWrapperProps<T> = (
  | {
      isNoData: boolean
      data?: never
    }
  | {
      isNoData: (data?: T) => boolean
      data: T
    }
  | {
      isNoData?: never
      data: T
    }
) & {
  children: React.ReactNode
  showLoader: boolean
  isError: boolean
  isRefetching?: boolean
  retryFunction?: () => void
  noDataSettings?: {
    noDataMessage?: string
    onActionClick?: ButtonProps['onClick']
    actionLabel?: string
  }
  progressSx?: SxProps<Theme>
  placeholderWrapperSx?: SxProps<Theme>
  translationPrefix?: string
}

function defaultIsNoDataCheck<T>(data?: T) {
  if (!data) return true
  if (isArray(data) || isObject(data)) return isEmpty(data)

  return false
}

export function DataContentWrapper<T>({
  showLoader,
  children,
  isError,
  isRefetching,
  data,
  isNoData = defaultIsNoDataCheck,
  retryFunction,
  progressSx,
  placeholderWrapperSx,
  translationPrefix,
  noDataSettings
}: DataContentWrapperProps<T>) {
  /**
   * Note: Currently we would show the loader only when the data is being fetched for the first time.
   * If the data is being re-fetched, we would show the error placeholder instead of the loader.
   */
  if (showLoader) {
    return (
      <PlaceholderWrapper noBackground wrapperSx={placeholderWrapperSx}>
        <CircularProgress sx={mergeSx({color: 'common.white'}, progressSx)} />
      </PlaceholderWrapper>
    )
  }

  if (isError) {
    return (
      <PlaceholderWrapper wrapperSx={placeholderWrapperSx}>
        <ErrorPlaceholder
          retryFunction={retryFunction}
          isRefetching={isRefetching}
          translationPrefix={translationPrefix}
        />
      </PlaceholderWrapper>
    )
  }

  if (typeof isNoData === 'function' ? isNoData(data) : isNoData) {
    return (
      <PlaceholderWrapper wrapperSx={placeholderWrapperSx}>
        <NoDataPlaceholder translationPrefix={translationPrefix} {...noDataSettings} />
      </PlaceholderWrapper>
    )
  }

  return <>{children}</>
}
