import {Iso8601} from '@hconnect/uikit'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {isEmpty} from 'lodash'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {PERFORMANCE_KPI_REQUEST_STALE_TIME} from '../../consts'
import {formatDateUtcToTimezoneDateOnly} from '../../helpers/dateFormat.helpers'
import {mapCementProductionQuarterlyDataDto} from '../../mappers'
import {QuarterlyForecastCementProductionHierarchy} from '../../types'

export const useCementProductionQuarterly = (
  timestamp: Iso8601,
  timezone: string,
  locationId?: string,
  options?: UseQueryOptions<QuarterlyForecastCementProductionHierarchy | null, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  const referenceDate = formatDateUtcToTimezoneDateOnly(timestamp, timezone)

  return useQuery<QuarterlyForecastCementProductionHierarchy | null, AxiosError>(
    ['cement-production-quarterly', referenceDate, locationId],
    async () => {
      if (!locationId) return Promise.resolve(null)

      const response = await axiosInstance.get<QuarterlyForecastCementProductionHierarchy>(
        '/kpi/dashboard/cement-production-qf',
        {
          params: {locationId, timestamp: referenceDate}
        }
      )
      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      select: (data) => {
        return data && !isEmpty(data) ? mapCementProductionQuarterlyDataDto(data) : null
      },
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      ...options
    }
  )
}
