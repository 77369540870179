import {EventAppInfo} from '@hconnect/common/types'
import {getTranslationKey, getEventRoute} from '@hconnect/common/utils'
import {AppLogo} from '@hconnect/uikit'
import {Typography, Paper, Link} from '@mui/material'
import {useTranslation} from 'react-i18next'

export const SourceAppInfo = ({
  app,
  externalId,
  plantId,
  translationPrefix
}: EventAppInfo & {translationPrefix?: string}) => {
  const {t} = useTranslation()

  const handleSourceAppLinkClick = () => {
    const url = getEventRoute({app, externalId, plantId})
    window.location.replace(url)
  }

  return (
    <Paper
      elevation={2}
      component="div"
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 2,
        borderLeft: 6,
        borderLeftColor: theme.palette.secondary.main
      })}
    >
      <Typography component={'span'} variant="body1">
        {t(getTranslationKey('createdInApp', translationPrefix), {appName: app})}
      </Typography>
      <Link underline="hover" onClick={handleSourceAppLinkClick}>
        <Typography sx={{display: 'flex', alignItems: 'center', gap: 1}}>
          <AppLogo name={app} />
          {t(getTranslationKey('openInApp', translationPrefix), {appName: app})}
        </Typography>
      </Link>
    </Paper>
  )
}
