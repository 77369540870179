import {ContentBox} from '@hconnect/common/components'
import {getTranslationKey, getHighlightedTitle} from '@hconnect/common/utils'
import CloseIcon from '@mui/icons-material/Close'
import {Typography, IconButton, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {mapToAppName} from '../../mappers'
import {HProduceTask} from '../../types'

import {MetaAnnotation} from './MetaAnnotation'
import {SourceAppInfo} from './SourceAppInfo'

export type Props = {
  item: HProduceTask
  onClose: (original?: HProduceTask, editVersion?: HProduceTask) => void
  freeSearchText?: string
  timezone: string
  translationPrefix?: string
  children?: React.ReactNode
}

const getTaskTitle = (title: string, freeSearchText?: string) =>
  freeSearchText ? getHighlightedTitle(title, freeSearchText) : title

export const TaskDetailsCard: React.FC<Props> = (props) => {
  const {item, onClose, freeSearchText, timezone, translationPrefix, children} = props
  const {t} = useTranslation()

  return (
    <ContentBox
      data-test-id="task-details"
      mode="max100PercentOfParentHeight"
      bodyWithPadding
      aboveTitle={
        <SourceAppInfo
          app={mapToAppName(item.source)}
          externalId={item.externalId}
          plantId={item.plantId}
          translationPrefix={translationPrefix}
        />
      }
      title={
        <Typography
          component="span"
          variant="h2"
          sx={{textTransform: 'none'}}
          data-test-id="task-details-title"
          display="flex"
          alignItems="center"
        >
          {getTaskTitle(item.title, freeSearchText)}
        </Typography>
      }
      afterTitle={
        <IconButton onClick={() => onClose()} data-test-id="task-details-close">
          <CloseIcon color="primary" />
        </IconButton>
      }
      headerSx={{paddingBottom: 0}}
    >
      <Stack spacing={2} useFlexGap>
        <MetaAnnotation
          label={t(getTranslationKey('metaAnnotation.label.created', translationPrefix))}
          date={item.created}
          timezone={timezone}
          data-test-id="meta-annotation-created-on"
          translationPrefix={translationPrefix}
        />
        {children}
      </Stack>
    </ContentBox>
  )
}
