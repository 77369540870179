import {OperationHoursDrawer} from '@hconnect/common/components/runningTimes'
import {SplitStoppageDialog} from '@hconnect/common/components/splitStoppage'
import {mapRunningTime, mapRunningTimeDto} from '@hconnect/common/mappers'
import {
  StoppageDto as Stoppage,
  RunningTimeDto,
  RunningTime,
  EquipmentRunningTimesDto as EquipmentRunningTimes,
  EquipmentData,
  RunningTimeStoppage
} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit'
import {PageContainer} from '@hconnect/uikit/src/lib2'
import {Grid, Theme, useMediaQuery} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'
import {generatePath} from 'react-router-dom'

import {StoppageAnalysisPageReferer} from '../../shared/consts'
import {useConfigData} from '../../shared/hooks/useConfig'
import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {
  PLANT_PROCESS_ENGINEER_DASHBOARD,
  PLANT_RELIABILITY_ENGINEER_DASHBOARD,
  PROCESS_ENGINEER_PLANT_KPI,
  RELIABILITY_ENGINEER_PLANT_KPI
} from '../../shared/routes'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {DateTimeRangePicker} from '../components/DateTimeRangePicker'
import {StoppageAnalysisGraph} from '../components/StoppageAnalysisGraph'
import {StoppageAnalysisList} from '../components/StoppageAnalysisList'
import {StoppageAnalysisSideCard} from '../components/stoppageAnalysisSideCard'
import {StoppageCreate} from '../components/StoppageCreate'
import {StoppageDelete} from '../components/StoppageDelete'
import {StatusPageHeader} from '../containers/StatusPageHeader'
import {generateStoppageDefaultData} from '../helpers'
import {usePlantStatus} from '../hooks/usePlantStatus'
import {useRunningTimes} from '../hooks/useRunningTimes'
import {usePlantTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {PLANT_KPI, PLANT_KPI_STATUS} from '../routing'
import {StoppageEvent, TimeRange} from '../types'

type PathParameter = {
  plantId: string
  equipmentId: string
}

const RefererPathMap: Record<StoppageAnalysisPageReferer, string> = {
  [StoppageAnalysisPageReferer.STATUS]: PLANT_KPI_STATUS,
  [StoppageAnalysisPageReferer.PERFORMANCE]: PLANT_KPI,
  [StoppageAnalysisPageReferer.PROCESS_ENGINEER_DASHBOARD]: PLANT_PROCESS_ENGINEER_DASHBOARD,
  [StoppageAnalysisPageReferer.PROCESS_ENGINEER_PLANT_STATUS]: PROCESS_ENGINEER_PLANT_KPI,
  [StoppageAnalysisPageReferer.RELIABILITY_ENGINEER_DASHBOARD]:
    PLANT_RELIABILITY_ENGINEER_DASHBOARD,
  [StoppageAnalysisPageReferer.RELIABILITY_ENGINEER_PLANT_STATUS]: RELIABILITY_ENGINEER_PLANT_KPI
}

type StoppageAnalysisProps = {
  referer?: StoppageAnalysisPageReferer
}

type StoppageDataDto = {
  runningTime: RunningTimeDto
  equipment?: EquipmentData
  stoppage?: RunningTimeStoppage
}
type StoppageData = {
  runningTime: RunningTime
  equipment?: EquipmentData
}

const mapStoppageData = (stoppageData: StoppageDataDto, timezone: string): StoppageData => ({
  ...stoppageData,
  runningTime: mapRunningTime(stoppageData.runningTime, timezone)
})

export const StoppageAnalysis: React.FC<StoppageAnalysisProps> = ({
  referer = StoppageAnalysisPageReferer.STATUS
}) => {
  const {plantId, equipmentId} = useParams<PathParameter>()
  if (!plantId) throw new Error('Missing plantId prop')
  const timezone = usePlantTimezone()
  const {from, to} = usePlantTimeRange()

  const {t} = useTranslation()
  const {performancePrefix, hrocPrefix} = useTranslationPrefix()
  const {notify} = useNotification()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false)
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState<boolean>(false)
  const [stoppageData, setStoppageData] = useState<Partial<Stoppage> | undefined>(undefined)
  const [equipmentIdToPreview, setEquipmentIdToPreview] = useState<string | undefined>('')
  const [stoppageToPreview, setStoppageToPreview] = useState<StoppageEvent | undefined>(undefined)
  const [stoppageIdToPreview, setStoppageIdToPreview] = useState<string | undefined>(undefined)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isSplitMode, _setIsSplitMode] = useState<boolean>(false)
  const [showDeleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [stoppageClickData, setStoppageClickData] = useState<StoppageDataDto>()

  const dateRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}

  const config = useConfigData()
  const {
    data,
    isInitialLoading: isInitialLoadingRunningTimes,
    refetch,
    isError,
    isRefetching
  } = useRunningTimes(dateRange, plantId, {
    onSuccess: (equipmentRunningTimes: EquipmentRunningTimes[]) => {
      if (equipmentRunningTimes) {
        const selectedEqAndRunningTimes = equipmentRunningTimes.find(
          (eq) => eq.equipment?.id === equipmentId
        )
        if (selectedEqAndRunningTimes?.equipment) {
          setEquipmentIdToPreview(selectedEqAndRunningTimes.equipment.id)
        }
      }
    }
  })
  const {data: plantData, isInitialLoading: isInitialLoadingPlantData} = usePlantStatus(
    plantId,
    dateRange,
    {
      onSuccess: (response) => {
        if (response.equipments) {
          const selectedEq = response.equipments.find((eq) => eq.id === equipmentId)
          if (selectedEq) {
            setEquipmentIdToPreview(selectedEq.id)
          }
        }
      }
    }
  )

  const onSubmit = useCallback(() => {
    handleClose()
    notify('success', t(`${performancePrefix}.stoppageAnalysis.action.creationSuccess`))
  }, [notify, t, performancePrefix])

  const handleClose = () => {
    setShowCreateForm(false)
  }

  const handleMobileClick = useCallback(
    (rt: RunningTimeDto, equipment?: EquipmentData, stoppage?: RunningTimeStoppage) => {
      setStoppageClickData({runningTime: rt, stoppage, equipment})
      setIsMobileDrawerOpen(true)
    },
    []
  )

  const handleSetEquipmentIdToPreview = useCallback((id?: string) => {
    setStoppageToPreview(undefined)
    setStoppageIdToPreview(undefined)
    setShowCreateForm(false)
    setEquipmentIdToPreview(id)
  }, [])

  const handleClick = useCallback(
    (rt: RunningTimeDto, equipment?: EquipmentData, stoppage?: RunningTimeStoppage) => {
      if (stoppage?.cockpitStoppageId) {
        setStoppageIdToPreview(stoppage.cockpitStoppageId)
        setStoppageToPreview(undefined)
        setShowCreateForm(false)
      } else {
        setShowCreateForm(true)
        setStoppageToPreview(undefined)
        setStoppageIdToPreview(undefined)
        if (rt.begin && equipment && config?.defaultCurrency) {
          const defaultValue = generateStoppageDefaultData({
            runningTime: rt,
            equipment,
            stoppage
          })
          setStoppageData(defaultValue)
        }
      }
    },
    [config?.defaultCurrency]
  )

  const onEditSuccess = useCallback(
    (stoppage: StoppageEvent) => {
      setIsEditMode(false)
      setStoppageToPreview(stoppage)
      notify('success', t(`${performancePrefix}.stoppageAnalysis.action.editionSuccess`))
    },
    [notify, t, performancePrefix]
  )

  const onDeleteSuccess = useCallback(() => {
    setStoppageToPreview(undefined)
    setDeleteDialog(false)
    notify('success', t(`${performancePrefix}.stoppageAnalysis.action.deleteSuccess`))
  }, [notify, t, performancePrefix])

  const setPreviewStoppage = (stoppage: StoppageEvent) => {
    setStoppageToPreview(stoppage)
    setStoppageIdToPreview(undefined)
    setShowCreateForm(false)
  }

  const setSplitMode = (stoppage: StoppageEvent) => {
    _setIsSplitMode(true)
    setStoppageToPreview(stoppage)
  }

  const handleStoppageSplit = (stoppage: Partial<Stoppage>) => {
    setStoppageIdToPreview(undefined)
    setStoppageToPreview(undefined)
    setShowCreateForm(true)
    setStoppageData(stoppage)
  }

  const stoppageId = stoppageToPreview?.id || stoppageIdToPreview

  const handlePreviewClose = () => {
    setStoppageToPreview(undefined)
    setStoppageIdToPreview(undefined)
  }

  const handleDelete = (stoppage: StoppageEvent) => {
    setDeleteDialog(true)
    setStoppageToPreview(stoppage)
  }

  const getTargetName = () => {
    switch (referer) {
      case StoppageAnalysisPageReferer.PROCESS_ENGINEER_DASHBOARD:
        return t(`${hrocPrefix}.processEngineerDashboard.pageTitle`)

      case StoppageAnalysisPageReferer.RELIABILITY_ENGINEER_DASHBOARD:
        return t(`${hrocPrefix}.reliabilityEngineerDashboard.pageTitle`)

      default:
        return plantData?.plant.name
    }
  }

  const getBackButtonProps = () => {
    return {
      targetName: t(`${performancePrefix}.stoppageAnalysis.label.backToPlant`, {
        target: getTargetName()
      }),

      target: generatePath(RefererPathMap[referer], {plantId})
    }
  }

  return (
    <PageContainer>
      <StatusPageHeader
        filterComponent={<DateTimeRangePicker type="all" />}
        title={t(`${performancePrefix}.stoppageAnalysis.label.title`)}
        backButtonProps={getBackButtonProps()}
      />

      <DataContentWrapper
        showLoader={isInitialLoadingRunningTimes || isInitialLoadingPlantData}
        data={data}
        retryFunction={() => void refetch()}
        isError={isError}
        isRefetching={isRefetching}
      >
        <StoppageAnalysisLayout
          isMobile={isMobile}
          stoppageAnalysisGraph={
            data && (
              <StoppageAnalysisGraph
                data={data}
                equipmentIdToPreview={equipmentIdToPreview}
                setEquipmentIdToPreview={handleSetEquipmentIdToPreview}
                handleStoppageClick={isMobile ? handleMobileClick : handleClick}
              />
            )
          }
          stoppageAnalysisList={
            <StoppageAnalysisList
              setStoppageToPreview={setPreviewStoppage}
              stoppageToPreview={stoppageToPreview}
            />
          }
          stoppageAnalysisSideCard={
            stoppageId && (
              <StoppageAnalysisSideCard
                isEditMode={isEditMode}
                onEdit={setIsEditMode}
                onDelete={handleDelete}
                onSplit={setSplitMode}
                stoppageId={stoppageId}
                onEditSuccess={onEditSuccess}
                plantId={plantId}
                onClose={handlePreviewClose}
              />
            )
          }
          showStoppageAnalysisSideCard={!!stoppageId}
          stoppageCreateForm={
            <StoppageCreate
              plantId={plantId}
              data-test-id="stoppage-analysis-graph-stoppage-create"
              doClose={handleClose}
              initialData={stoppageData}
              onSuccessSubmit={onSubmit}
            />
          }
          showCreateForm={showCreateForm}
        />
      </DataContentWrapper>

      {isSplitMode && stoppageToPreview && (
        <SplitStoppageDialog
          timezone={timezone}
          open={isSplitMode}
          onClose={() => _setIsSplitMode(false)}
          stoppage={stoppageToPreview}
          onSplitSubmit={handleStoppageSplit}
          translationPrefix={performancePrefix}
          pxTrendAvailable={config?.pxTrendAvailable}
        />
      )}

      {!!(showDeleteDialog && stoppageToPreview) && (
        <StoppageDelete
          plantId={plantId}
          onSuccess={onDeleteSuccess}
          setDialogOpen={setDeleteDialog}
          stoppage={stoppageToPreview}
          showDialog={true}
          translationPrefix={performancePrefix}
        />
      )}
      {isMobile && (
        <OperationHoursDrawer
          isOpen={isMobileDrawerOpen}
          onClose={() => setIsMobileDrawerOpen(false)}
          stoppageData={stoppageClickData && mapStoppageData(stoppageClickData, timezone)}
          handleStoppageClick={(rt: RunningTime, equipment?: EquipmentData) =>
            handleClick(mapRunningTimeDto(rt), equipment)
          }
          labels={{
            createIncident: t(`${performancePrefix}.stoppageAnalysis.label.createIncident`),
            editIncident: t(`${performancePrefix}.stoppageAnalysis.label.editIncident`),
            stoppage: t(`${performancePrefix}.stoppageAnalysis.label.stoppage`),
            running: t(`${performancePrefix}.stoppageAnalysis.label.running`)
          }}
        />
      )}
    </PageContainer>
  )
}

type LayoutProps = {
  stoppageAnalysisGraph: React.ReactNode
  stoppageAnalysisList: React.ReactNode
  stoppageAnalysisSideCard: React.ReactNode
  stoppageCreateForm: React.ReactNode
  showStoppageAnalysisSideCard: boolean
  showCreateForm: boolean
  isMobile?: boolean
}

const StoppageAnalysisLayout: React.FC<LayoutProps> = ({isMobile, ...layoutProps}) => {
  return isMobile ? <MobileLayout {...layoutProps} /> : <DesktopLayout {...layoutProps} />
}

const DesktopLayout = ({
  stoppageAnalysisGraph,
  stoppageAnalysisList,
  stoppageAnalysisSideCard,
  stoppageCreateForm,
  showStoppageAnalysisSideCard,
  showCreateForm
}: LayoutProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {stoppageAnalysisGraph}
      </Grid>
      <Grid item xs={12} sm={8}>
        {stoppageAnalysisList}
      </Grid>
      {showStoppageAnalysisSideCard && (
        <Grid item xs={12} sm={4}>
          {stoppageAnalysisSideCard}
        </Grid>
      )}
      {showCreateForm && !showStoppageAnalysisSideCard && (
        <Grid item xs={12} sm={4}>
          {stoppageCreateForm}
        </Grid>
      )}
    </Grid>
  )
}

const MobileLayout = ({
  stoppageAnalysisGraph,
  stoppageAnalysisList,
  stoppageAnalysisSideCard,
  stoppageCreateForm,
  showStoppageAnalysisSideCard,
  showCreateForm
}: LayoutProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {stoppageAnalysisGraph}
      </Grid>
      <Grid item xs={12}>
        {showStoppageAnalysisSideCard
          ? stoppageAnalysisSideCard
          : showCreateForm
            ? stoppageCreateForm
            : stoppageAnalysisList}
      </Grid>
    </Grid>
  )
}
