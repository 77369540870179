import {useTranslation} from 'react-i18next'

import {
  DataContentWrapper as SharedDataContentWrapper,
  DataContentWrapperProps
} from '../../shared/components'
import {useTranslationPrefix} from '../../shared/hooks'

export function DataContentWrapper<T>(props: DataContentWrapperProps<T>) {
  const {performancePrefix} = useTranslationPrefix()
  const {t} = useTranslation()

  return (
    <SharedDataContentWrapper
      {...props}
      translationPrefix={performancePrefix}
      noDataSettings={{
        ...props.noDataSettings,
        noDataMessage:
          props.noDataSettings?.noDataMessage ?? t(`${performancePrefix}.plantStatus.label.noData`)
      }}
    />
  )
}
