import {StoppageKind} from '@hconnect/common/types'
import {DateRange} from '@hconnect/uikit'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {prepareAggregatedStoppageCodePayload} from '../helpers'
import {EquipmentProductionTime} from '../types'

const QueryKey = 'stoppage-equipment'

export type EquipmentKPIsQueryParams = {
  timeRange: DateRange<Date>
  mainEquipmentNumbers?: string[]
  stoppageCodes?: string[]
  stoppageKinds?: StoppageKind[]
  equipmentNumbers?: string[]
}

export const useEquipmentProductionTime = (
  plantId: string,
  payload: EquipmentKPIsQueryParams,
  options?: UseQueryOptions<EquipmentProductionTime[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<EquipmentProductionTime[], AxiosError>(
    [QueryKey, plantId, payload],
    async () => {
      const response = await axiosInstance.get<EquipmentProductionTime[]>(
        `/downtime-dashboard/${plantId}/equipment-production-times`,
        {
          params: prepareAggregatedStoppageCodePayload({
            to: payload.timeRange.to,
            from: payload.timeRange.from,
            mainEquipmentNumbers: payload.mainEquipmentNumbers,
            equipmentNumbers: payload.equipmentNumbers,
            kinds: payload.stoppageKinds,
            stoppageCodes: payload.stoppageCodes
          })
        }
      )

      return response.data
    },
    {
      staleTime: 1000 * 60 * 15, // 15 mins
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
