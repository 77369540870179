import {useQueries, UseQueryResult} from '@tanstack/react-query'
import {AxiosError, AxiosInstance} from 'axios'
import {useCallback, useMemo} from 'react'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {PERFORMANCE_KPI_REQUEST_STALE_TIME} from '../../consts'
import {mapTechnicalCementPowerConsumptionDto} from '../../mappers'
import {
  MtmYtmRequestPayload,
  KpiPfyPayload,
  PlannedYearlyTechnicalCementPowerConsumptionsHierarchy,
  TechnicalCementPowerConsumptionResponse
} from '../../types'

import {KpiValue} from './commonKpiApiTypes'
import {
  getTechnicalCementPowerConsumption,
  getTechnicalCementPowerConsumptionMtmYtmQueryKeys
} from './useTechnicalCementPowerConsumption'

export const getTechnicalCementPowerConsumptionPfy = async (
  axiosInstance: AxiosInstance,
  payload?: KpiPfyPayload
) => {
  const url = '/kpi/dashboard/technical-cement-power-consumption-pfy'
  const response = await axiosInstance.get<PlannedYearlyTechnicalCementPowerConsumptionsHierarchy>(
    url,
    {
      params: {...payload}
    }
  )

  return response.data
}

export const useTechnicalCementPowerConsumptionData = (
  payload: MtmYtmRequestPayload,
  enabled = true
): KpiValue => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  const queries = useQueries<
    [
      UseQueryResult<PlannedYearlyTechnicalCementPowerConsumptionsHierarchy, AxiosError>,
      UseQueryResult<TechnicalCementPowerConsumptionResponse, AxiosError>
    ]
  >({
    queries: [
      {
        queryKey: [
          'technical-cement-power-consumption-pfy',
          {locationId: payload?.locationId, year: payload?.year}
        ],
        queryFn: () =>
          getTechnicalCementPowerConsumptionPfy(axiosInstance, {
            locationId: payload?.locationId,
            year: payload?.year
          }),
        enabled,
        staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
        onError: (err: AxiosError) => handleError(err, navigate)
      },
      {
        queryKey: getTechnicalCementPowerConsumptionMtmYtmQueryKeys(payload),
        queryFn: () =>
          getTechnicalCementPowerConsumption<TechnicalCementPowerConsumptionResponse>(
            axiosInstance,
            payload
          ),
        enabled,
        staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
        onError: (err: AxiosError) => handleError(err, navigate)
      }
    ]
  })

  const refetch = useCallback(() => {
    queries.forEach(({error, refetch: queryRefetch}) => {
      if (error) {
        void queryRefetch()
      }
    })
  }, [queries])

  const [pfyQuery, dataQuery] = queries

  const technicalCementPowerConsumption = useMemo(() => {
    const pfyData = pfyQuery.data
    const data = dataQuery.data
    return pfyData && data ? mapTechnicalCementPowerConsumptionDto(data, pfyData) : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(pfyQuery.data), JSON.stringify(dataQuery.data)])

  return {
    data: technicalCementPowerConsumption,
    isInitialLoading: pfyQuery.isInitialLoading || dataQuery.isInitialLoading,
    error: pfyQuery.error || dataQuery.error,
    refetch
  }
}
