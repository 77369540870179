import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {StoppageCode} from '../types'

const QueryKey = 'plant-stoppages'
const INTERVAL_PERIOD_IN_MS = 300000

export const usePlantStoppages = (
  plantId: string,
  options?: UseQueryOptions<StoppageCode[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<StoppageCode[], AxiosError>(
    [QueryKey, plantId],
    async () => {
      const response = await axiosInstance.get<StoppageCode[]>(
        `/plantconfig/${plantId}/stoppage-codes`
      )

      return response.data
    },
    {
      refetchInterval: INTERVAL_PERIOD_IN_MS,
      onError: (err) => handleError(err, navigate),
      staleTime: Infinity,
      ...options
    }
  )
}
