import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {NavigationAccess} from '../../shared/types/navigationAccess.types'

const QueryKey = 'equipment-status'
const PATH = '/kpi/dashboard/accessibility'
const INTERVAL_PERIOD_IN_MS = 300000

export const useNavAccessibility = (options?: UseQueryOptions<NavigationAccess, AxiosError>) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<NavigationAccess, AxiosError>(
    [QueryKey],
    async () => {
      const response = await axiosInstance.get<NavigationAccess>(PATH)

      return response.data
    },
    {
      refetchInterval: INTERVAL_PERIOD_IN_MS,
      refetchOnMount: false,
      onError: (err) => handleError(err, navigate),
      ...options,
      onSuccess: (data) => {
        options?.onSuccess?.(data)
      }
    }
  )
}
