import {DateTimeFormat} from '@hconnect/common/components/dateTime/DateTimeFormat'
import {EventStatusLabel} from '@hconnect/common/components/eventProperties/EventStatusLabel'
import {OverdueLabel} from '@hconnect/common/components/eventProperties/OverdueLabel'
import {TableSize} from '@hconnect/common/components/eventsList/types'
import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {StatusWithCancellation} from '@hconnect/common/types'
import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {PaginationOptions} from '@hconnect/uikit'
import {DataTable, MarkdownText} from '@hconnect/uikit/src/lib2'
import type {Column} from '@hconnect/uikit/src/lib2'
import {Typography} from '@mui/material'
import {TFunction} from 'i18next'
import moment from 'moment-timezone'
import {useTranslation} from 'react-i18next'

import {selectableTableRowSx} from '../../shared/styles'
import {HProduceTask, SortOrder, TasksTableKey, TasksTableSortOptions} from '../types'

const generateColumnsMap = (t: TFunction, timezone: string) => ({
  XS: [generateTitleColumn(t)],
  S: [generateTitleColumn(t), generateStatusColumn(t, 'small')],
  M: [generateTitleColumn(t), generateStatusColumn(t, 'small')],
  L: [
    generateTitleColumn(t, false),
    generateAssigneeColumn(t),
    generateDueDateColumn(t, timezone),
    generateCreatedAtColumn(t, timezone),
    generateStatusColumn(t, 'small'),
    generatePlantColumn(t)
  ],
  XL: [
    generateTitleColumn(t, false),
    generateAssigneeColumn(t),
    generateDueDateColumn(t, timezone),
    generateCreatedAtColumn(t, timezone),
    generateStatusColumn(t, 'regular'),
    generatePlantColumn(t)
  ]
})

type Props = {
  data: HProduceTask[]
  isLoading: boolean
  paginationOptions?: PaginationOptions
  sortOptions?: TasksTableSortOptions
  translationPrefix?: string
  selectedItemId?: string
  showDescriptions?: boolean
  onRowClick: (clickedItem: HProduceTask) => void
  size?: TableSize
  areFiltersApplied: boolean
  timezone: string
}

export const TasksTable = ({
  data,
  isLoading,
  paginationOptions,
  sortOptions,
  translationPrefix,
  selectedItemId,
  onRowClick,
  showDescriptions,
  size = 'L',
  timezone,
  areFiltersApplied
}: Props) => {
  const {t} = useTranslation()
  const columnsMap = generateColumnsMap(
    ((key: string) => t(getTranslationKey(key, translationPrefix))) as TFunction,
    timezone
  )
  const columns = columnsMap[size]
  const emptyMessageTranslationKey = areFiltersApplied
    ? 'tasksTable.noDataForSelectedFilters'
    : 'label.noData'

  return (
    <DataTable<HProduceTask>
      columns={columns}
      data={data ?? []}
      loading={isLoading}
      paginationOptions={paginationOptions}
      {...(sortOptions && getSortProps(sortOptions))}
      emptyMessage={
        (!isLoading && t(getTranslationKey(emptyMessageTranslationKey, translationPrefix))) ?? ''
      }
      data-test-id="tasks-table"
      onRowClick={(_, clickedItem) => onRowClick(clickedItem)}
      rowSx={(item) => selectableTableRowSx(selectedItemId === item.id)}
      rowFooterTemplate={showDescriptions ? ({rowData}) => renderDescription(rowData) : undefined}
    />
  )
}

const getSortProps = (sortOptions: TasksTableSortOptions) => ({
  sortedBy: sortOptions.sortBy,
  sortDirection: getSortDirection(sortOptions.sortOrder),
  onSort: (_: unknown, columnKey: TasksTableKey) => {
    const newSortOrder = getNextSortOrder(sortOptions, columnKey)
    sortOptions.onSortChange?.(columnKey, newSortOrder)
  }
})

const getSortDirection = (sortOrder?: SortOrder): 'asc' | 'desc' | undefined => {
  if (!sortOrder) {
    return undefined
  }
  return sortOrder === SortOrder.ASCENDING ? 'asc' : 'desc'
}

const getNextSortOrder = (
  sortOptions: TasksTableSortOptions,
  columnKey: TasksTableKey
): SortOrder => {
  return sortOptions.sortBy === columnKey && sortOptions.sortOrder === SortOrder.ASCENDING
    ? SortOrder.DESCENDING
    : SortOrder.ASCENDING
}

const renderDescription = ({id, description}: HProduceTask) => (
  <Typography
    component="div"
    sx={{
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden'
    }}
    data-test-id={`tasks-table-${id}-description`}
    color="textSecondary"
    variant="body2"
  >
    {description && <MarkdownText>{description}</MarkdownText>}
  </Typography>
)

export const generateTitleColumn = <T extends HProduceTask>(
  t: TFunction,
  includeOverdueLabel: boolean = true
): Column<T> => ({
  key: TasksTableKey.TITLE,
  label: t('tasksTable.label.title'),
  flex: 4,
  sortable: true,
  customTemplate: (item: T) =>
    includeOverdueLabel ? (
      <>
        {item.title}{' '}
        <OverdueLabel
          status={item.status as StatusWithCancellation}
          dueDate={moment(item.dueDate)}
          plantNow={moment()}
          translationFunc={t}
        />
      </>
    ) : (
      item.title
    )
})

export const generateStatusColumn = <T extends HProduceTask>(
  t: TFunction,
  type?: 'small' | 'regular'
): Column<T> => ({
  headProps: type === 'small' ? {style: {paddingLeft: '4px'}} : {},
  key: TasksTableKey.STATUS,
  width: type === 'small' ? '0px' : undefined,
  label: t('tasksTable.label.status'),
  sortable: true,
  customTemplate: (item: T) => (
    <EventStatusLabel status={item.status} iconOnly={type === 'small'} t={t} />
  )
})

export const generateDueDateColumn = <T extends HProduceTask>(
  t: TFunction,
  timezone: string
): Column<T> => ({
  key: TasksTableKey.DUE_DATE,
  label: t('tasksTable.label.dueDate'),
  flex: 2,
  sortable: true,
  customTemplate: (item: T) => (
    <>
      <DateTimeFormat date={item.dueDate} timezone={timezone} />{' '}
      <OverdueLabel
        status={item.status as StatusWithCancellation}
        dueDate={moment(item.dueDate)}
        plantNow={moment()}
        translationFunc={t}
      />
    </>
  )
})

export const generateAssigneeColumn = <T extends HProduceTask>(t: TFunction): Column<T> => ({
  key: TasksTableKey.ASSIGNEE,
  label: t('tasksTable.label.assignee'),
  flex: 2,
  sortable: true,
  customTemplate: ({assignees}) =>
    assignees?.length ? assignees.map(({name}) => name).join(', ') : '-'
})

export const generateCreatedAtColumn = <T extends HProduceTask>(
  t: TFunction,
  timezone: string
): Column<T> => ({
  key: TasksTableKey.CREATED,
  label: t('tasksTable.label.createdAt'),
  flex: 2,
  sortable: true,
  customTemplate: (item: T) => <DateTimeFormat date={item.created} timezone={timezone} />
})

export const generatePlantColumn = <T extends HProduceTask>(t: TFunction): Column<T> => ({
  key: TasksTableKey.PLANT,
  label: t('tasksTable.label.plant'),
  flex: 2,
  sortable: false,
  customTemplate: ({plantId}) => getPlantName(plantId)
})
