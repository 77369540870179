import {isSameDate} from '@hconnect/uikit/src/common'
import {Box, FormControlLabel, Grid, Paper, Switch, Typography} from '@mui/material'
import React, {useMemo, useState} from 'react'
import {getI18n, useTranslation} from 'react-i18next'
import {generatePath} from 'react-router'

import {DowntimeFilters} from '../components/downtime/filters/Filters'
import {StoppageDetailsTable} from '../components/downtime/StoppageDetailsTable'
import {TopIssuesByCode} from '../components/downtime/TopIssuesByCode'
import {DOWNTIME_DETAILS_FOR_MAIN_EQUIPMENT_AND_CODE_FILTER_OPTIONS} from '../consts'
import {
  getFailureKindsFromUrl,
  isUnavailable,
  DowntimeAnalyticsEventName,
  useTrackDowntimeAnalyticsEvent
} from '../helpers'
import {useDowntimeStoppageDetailsStates} from '../hooks/useDowntimeStoppageDetailsStates'
import {usePlantStoppagesDetails} from '../hooks/usePlantStoppagesDetails'
import {useTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {
  PLANT_DOWNTIME_STOPPAGES_DETAILS,
  PLANT_DOWNTIME_STOPPAGES_DETAILS_FOR_CODE
} from '../routing'
import {DowntimeStoppage, IssueGroupingKey, StoppageCode} from '../types'

import {StatusPageHeader} from './StatusPageHeader'

type EquipmentStoppageDetailsForCodeProps = {
  plantId: string
  groupType: IssueGroupingKey.MAIN_EQUIPMENT | IssueGroupingKey.EQUIPMENT_NUMBER
  groupId: string
  stoppageCode: string
  stoppageConfig?: StoppageCode[]
}

const getTheTitle = ({
  performancePrefix,
  groupType,
  groupId,
  stoppageCode,
  stoppagesDetails = [],
  stoppageConfig = []
}: {
  performancePrefix: string
  groupType: IssueGroupingKey.MAIN_EQUIPMENT | IssueGroupingKey.EQUIPMENT_NUMBER
  groupId: string
  stoppageCode: string
  stoppagesDetails?: DowntimeStoppage[]
  stoppageConfig?: StoppageCode[]
}) => {
  const mainEquipmentText =
    stoppagesDetails.find((stoppage) => !!stoppage.mainEquipment?.text)?.mainEquipment.text ??
    groupId
  const equipmentNumberText =
    stoppagesDetails.find((stoppage) => !!stoppage.equipment?.text)?.equipment.text ?? groupId
  const equipmentText =
    groupType === IssueGroupingKey.MAIN_EQUIPMENT ? mainEquipmentText : equipmentNumberText

  const stoppage = stoppageConfig?.find((stoppage) => stoppage.code === stoppageCode)
  const stoppageCodeText = isUnavailable(stoppageCode)
    ? getI18n().t(`${performancePrefix}.shiftEvent.stoppage.stoppageTypeOther`)
    : stoppage?.text

  return `${equipmentText} - ${stoppageCodeText}`
}

export const EquipmentStoppageDetailsForCode: React.FC<EquipmentStoppageDetailsForCodeProps> = ({
  stoppageConfig,
  plantId,
  groupType,
  groupId,
  stoppageCode
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const [selectedStoppageCodes, setSelectedStoppageCodes] = useState<string[] | undefined>([
    stoppageCode
  ])
  const [showDescriptions, setShowDescriptions] = useState(false)
  const timeRange = useTimeRange()
  const trackAnalyticsEvent = useTrackDowntimeAnalyticsEvent()

  const {
    mainEquipments,
    setMainEquipments,
    equipmentNumbers,
    setEquipmentNumbers,
    failureStoppage,
    setFailureStoppage
  } = useDowntimeStoppageDetailsStates({
    groupType,
    groupId
  })

  const {
    data: stoppagesDetails = [],
    isInitialLoading: isStoppageDetailsLoading,
    isError: isStoppageDetailsError,
    refetch: refetchStoppageDetails,
    isRefetching: isStoppageDetailsRefetching
  } = usePlantStoppagesDetails(plantId, {
    ...timeRange,
    selectedMainEquipments: mainEquipments?.map((eq) => eq.id),
    selectedStoppages: getFailureKindsFromUrl(failureStoppage),
    selectedEquipments: equipmentNumbers?.map((eq) => eq.id),
    selectedStoppageCodes: [stoppageCode]
  })

  const title = getTheTitle({
    performancePrefix,
    groupType,
    groupId,
    stoppageCode,
    stoppagesDetails,
    stoppageConfig
  })

  const timeFramesTitle = useMemo(() => {
    const singleDate = isSameDate(timeRange.from, timeRange.to)
    return timeRange.quickSelectionId
      ? `${t(`${performancePrefix}.quickSelection.${timeRange.quickSelectionId}`)}`
      : `${timeRange.from.toDateString()}${singleDate ? '' : ` - ${timeRange.to.toDateString()}`}`
  }, [timeRange, t, performancePrefix])

  return (
    <>
      <StatusPageHeader
        title={title}
        backButtonProps={{
          target: generatePath(PLANT_DOWNTIME_STOPPAGES_DETAILS, {
            groupType,
            groupId,
            plantId
          }),
          targetName: t(`${performancePrefix}.downtime.actions.backToStoppagesDetails`)
        }}
        filterComponent={
          <DowntimeFilters
            failureState={{
              failureStoppage: getFailureKindsFromUrl(failureStoppage),
              setFailureStoppage: (failures) => setFailureStoppage(failures?.join(','))
            }}
            mainEquipmentState={{mainEquipments, setMainEquipments}}
            stoppageCodesState={{
              stoppageCodes: selectedStoppageCodes,
              setStoppageCodes: setSelectedStoppageCodes
            }}
            equipmentNumbersState={{equipmentNumbers, setEquipmentNumbers}}
            options={DOWNTIME_DETAILS_FOR_MAIN_EQUIPMENT_AND_CODE_FILTER_OPTIONS[groupType]}
            path={generatePath(PLANT_DOWNTIME_STOPPAGES_DETAILS_FOR_CODE, {
              plantId,
              groupType,
              groupId,
              stoppageCode
            })}
          />
        }
      />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <TopIssuesByCode
            plantId={plantId}
            stoppageConfig={stoppageConfig}
            selectedEquipments={mainEquipments?.map((eq) => eq.id)}
            selectedEquipmentNumbers={equipmentNumbers?.map((eq) => eq.id)}
            selectedStoppages={getFailureKindsFromUrl(failureStoppage)}
            selectedStoppageCodes={selectedStoppageCodes}
            title={t(`${performancePrefix}.downtime.label.downtimes`)}
            issueCount={10}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Paper sx={{padding: 1}}>
            <Box display={'flex'} justifyContent={'space-between'} mb={1}>
              <Box display="flex" m={2} flexDirection="column">
                <Typography variant="subtitle1">{timeFramesTitle}</Typography>
              </Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={showDescriptions}
                    data-test-id="events-show-descriptions-toggle"
                    onChange={() => {
                      setShowDescriptions(!showDescriptions)
                      trackAnalyticsEvent(
                        DowntimeAnalyticsEventName.UserAdjustDescriptionColumn,
                        {}
                      )
                    }}
                    color="primary"
                  />
                }
                label={t(`${performancePrefix}.downtime.actions.toggleDescriptions`)}
              />
            </Box>
            <StoppageDetailsTable
              refetch={() => void refetchStoppageDetails()}
              isRefetching={isStoppageDetailsRefetching}
              isLoading={isStoppageDetailsLoading}
              isError={isStoppageDetailsError}
              stoppagesDetails={stoppagesDetails}
              showDescriptions={showDescriptions}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
