import {StoppageKind} from '@hconnect/common/types'
import {DateRange} from '@hconnect/uikit'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {prepareAggregatedStoppageCodePayload} from '../helpers'
import {DowntimeStoppage} from '../types'

const QueryKey = 'plant-stoppages-details'

export const usePlantStoppagesDetails = (
  plantId: string,
  payload: DateRange<Date> & {
    selectedMainEquipments?: string[]
    selectedEquipments?: string[]
    selectedStoppages?: StoppageKind[]
    selectedStoppageCodes?: string[]
  },
  options?: UseQueryOptions<DowntimeStoppage[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<DowntimeStoppage[], AxiosError>(
    [QueryKey, plantId, payload],
    async () => {
      const path = `/downtime-dashboard/${plantId}/stoppages`
      const response = await axiosInstance.get<DowntimeStoppage[]>(path, {
        params: prepareAggregatedStoppageCodePayload({
          to: payload.to,
          from: payload.from,
          mainEquipmentNumbers: payload.selectedMainEquipments,
          equipmentNumbers: payload.selectedEquipments,
          kinds: payload.selectedStoppages,
          stoppageCodes: payload.selectedStoppageCodes
        })
      })

      return response.data
    },
    {
      staleTime: 1000 * 60 * 15, // 15 mins
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
