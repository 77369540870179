import {StoppageDto as Stoppage} from '@hconnect/common/types'
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../shared/hooks/useApi'
import {prepareStoppagePayload} from '../helpers'
import {StoppageEdit} from '../types'

export const useStoppageUpdate = (
  plantId: string,
  options?: UseMutationOptions<Stoppage, AxiosError, StoppageEdit>
) => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()

  return useMutation<Stoppage, AxiosError, StoppageEdit>(
    async (stoppage) => {
      const formData = await prepareStoppagePayload(stoppage, ['attachments'])
      const path = `/shifthandover/${plantId}/stoppages`

      const response = await axiosInstance.put<Stoppage>(path, formData)
      return response.data
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void options?.onSuccess?.(data, variables, context)
        void queryClient.invalidateQueries()
      }
    }
  )
}
