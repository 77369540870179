import {Iso8601} from '@hconnect/uikit'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError, AxiosInstance} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {PERFORMANCE_KPI_REQUEST_STALE_TIME} from '../../consts'
import {formatDateUtcToTimezoneDateOnly} from '../../helpers/dateFormat.helpers'
import {
  KilnHeatConsumptionsResponse,
  QuarterlyForecastKilnHeatConsumptionsHierarchy,
  MtmYtmRequestPayload
} from '../../types'

export const getKilnHeatConsumptionMtmYtmUrlSuffix = (showYTM?: boolean) =>
  showYTM ? 'kiln-heat-consumptions-ytm' : 'kiln-heat-consumptions-mtm'

export const getKilnHeatConsumptionMtmYtmQueryKeys = (requestPayload: MtmYtmRequestPayload) => [
  getKilnHeatConsumptionMtmYtmUrlSuffix(requestPayload.showYTM),
  formatDateUtcToTimezoneDateOnly(requestPayload.timestamp, requestPayload.timezone),
  requestPayload.locationId
]

export const getKilnHeatConsumption = async (
  axiosInstance: AxiosInstance,
  {timestamp, locationId, timezone, showYTM}: MtmYtmRequestPayload
) => {
  const referenceDate = formatDateUtcToTimezoneDateOnly(timestamp, timezone)

  const url = `/kpi/dashboard/${getKilnHeatConsumptionMtmYtmUrlSuffix(showYTM)}`
  const response = await axiosInstance.get<KilnHeatConsumptionsResponse>(url, {
    params: {locationId, date: referenceDate}
  })

  return response.data
}

export const useKilnHeatConsumption = <T>(
  payload: MtmYtmRequestPayload,
  options?: UseQueryOptions<KilnHeatConsumptionsResponse, AxiosError, T>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const QueryKeys = getKilnHeatConsumptionMtmYtmQueryKeys(payload)

  return useQuery<KilnHeatConsumptionsResponse, AxiosError, T>(
    QueryKeys,
    async () => {
      return await getKilnHeatConsumption(axiosInstance, payload)
    },
    {
      onError: (err) => handleError(err, navigate),
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      ...options
    }
  )
}

export const useKilnHeatConsumptionQuarterly = (
  timestamp: Iso8601,
  timezone: string,
  locationId?: string,
  options?: UseQueryOptions<QuarterlyForecastKilnHeatConsumptionsHierarchy | null, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const referenceDate = formatDateUtcToTimezoneDateOnly(timestamp, timezone)

  return useQuery<QuarterlyForecastKilnHeatConsumptionsHierarchy | null, AxiosError>(
    ['kiln-heat-consumptions-quarterly', referenceDate, locationId],
    async () => {
      if (!locationId) return Promise.resolve(null)

      const response = await axiosInstance.get<QuarterlyForecastKilnHeatConsumptionsHierarchy>(
        '/kpi/dashboard/kiln-heat-consumptions-qf',
        {
          params: {locationId, timestamp: referenceDate}
        }
      )
      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      ...options
    }
  )
}
