import {ContentBox} from '@hconnect/common/components/ContentBox'
import {SideCardModes} from '@hconnect/common/components/eventsList/types'
import {SIDE_CARD_MIN_HEIGHT_IN_PX} from '@hconnect/common/consts'
import React from 'react'

import {HProduceTask} from '../types'

import {TaskDetailsFactory} from './TaskDetails'

// TODO: make it less dependant on the ShiftEvent in following tasks
type Props = SideCardModes<HProduceTask> & {
  setMode: (next: SideCardModes<HProduceTask>) => void
  doClose: (original?: HProduceTask, next?: HProduceTask) => void
  freeSearchText?: string
  timezone: string
  task?: HProduceTask
  translationPrefix?: string
}

export const SideCard: React.FC<Props> = ({task, ...props}) => {
  // TODO: setup permission
  // const allowedToViewDocuments = usePermission('VIEW_DOCUMENTS')

  if (task) {
    switch (props.mode) {
      case 'detailsMode':
        return (
          <TaskDetailsFactory
            data-test-id="page-events-details"
            onClose={props.doClose}
            task={task}
            freeSearchText={props.freeSearchText}
            timezone={props.timezone}
            translationPrefix={props.translationPrefix}
          />
        )

      case 'editMode':
        return <>add edit mode</>
    }
  }

  return (
    <ContentBox
      data-test-id="sideCard-spinner"
      isLoading
      minHeight={SIDE_CARD_MIN_HEIGHT_IN_PX}
      mode="max100PercentOfParentHeight"
    />
  )
}
