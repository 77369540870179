import {getTranslationKey} from '@hconnect/common/utils'
import {Button, ButtonProps, Stack, Typography} from '@mui/material'
import {isFunction} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

type NoDataPlaceholderProps = {
  noDataMessage?: string
  translationPrefix?: string
  onActionClick?: ButtonProps['onClick']
  actionLabel?: string
}

export const NoDataPlaceholder: React.FC<NoDataPlaceholderProps> = ({
  noDataMessage,
  translationPrefix,
  onActionClick,
  actionLabel
}) => {
  const {t} = useTranslation()

  return (
    <Stack spacing={2} alignItems="center">
      <Typography>
        <Typography>{noDataMessage ?? t(`${translationPrefix}.label.noData`)}</Typography>
      </Typography>
      {isFunction(onActionClick) && (
        <Button
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            onActionClick(event)
          }}
          variant="outlined"
          color="primary"
        >
          {actionLabel ?? t(getTranslationKey('error.action.close', translationPrefix))}
        </Button>
      )}
    </Stack>
  )
}
