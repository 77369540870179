import {ActionSource, EquipmentData, QuestionDifficulty} from '@hconnect/common/types'
import {
  equipmentToParameter,
  isActionSource,
  isQuestionDifficulty,
  parameterToEquipment
} from '@hconnect/common/utils'
import {intersection} from 'lodash'

import {
  getArrayUrlParam,
  getBooleanUrlParam,
  getUrlParam,
  setOrDeleteArrayParam,
  setOrDeleteBooleanParam,
  setOrDeleteStringParam
} from '../../shared/helpers'
import {TasksPageParamKey} from '../consts'
import {
  CockpitCategory,
  SortOrder,
  TaskSource,
  TasksPageQueryParams,
  TasksSortBy,
  TaskStatus
} from '../types'

import {
  isCockpitCategory,
  isSortOrder,
  isTaskSource,
  isTasksSortBy,
  isTaskStatus
} from './tasks.utils'

export function urlParamsToTasksPageQueryParams(params: URLSearchParams): TasksPageQueryParams {
  const tasksQuickFilters = getTasksQuickFilters(params)
  const tasksPageFilters = getTasksPageFilters(params)

  return {
    ...tasksQuickFilters,
    ...tasksPageFilters
  }
}

function getTasksQuickFilters(params: URLSearchParams): TasksPageQueryParams {
  const assignedToMe = getBooleanUrlParam(params, TasksPageParamKey.ASSIGNED_TO_ME)
  const sources = getArrayUrlParam<TaskSource>(params, TasksPageParamKey.SOURCES)
  const sanitizedSources = sources?.filter(isTaskSource)

  return {
    assignedToMe,
    sources: sanitizedSources
  }
}

function getTasksPageFilters(params: URLSearchParams): TasksPageQueryParams {
  const title = getUrlParam(params, TasksPageParamKey.TITLE)
  const dueDateRange = getUrlParam(params, TasksPageParamKey.DUE_DATE_RANGE)
  const statuses = getArrayUrlParam<TaskStatus>(params, TasksPageParamKey.STATUSES)
  const statusesSanitized = statuses?.filter(isTaskStatus)
  const assignee = getUrlParam(params, TasksPageParamKey.ASSIGNEE)
  const categories = getArrayUrlParam<CockpitCategory>(params, TasksPageParamKey.CATEGORIES)
  const categoriesSanitized = categories?.filter(isCockpitCategory)
  const priorities = getArrayUrlParam<number>(params, TasksPageParamKey.PRIORITIES, Number)
  const pomActionSources = getArrayUrlParam<ActionSource>(
    params,
    TasksPageParamKey.POM_ACTION_SOURCES
  )
  const pomActionSourcesSanitized = pomActionSources?.filter(isActionSource)
  const pomQuestionCategories = getArrayUrlParam<QuestionDifficulty>(
    params,
    TasksPageParamKey.POM_QUESTION_CATEGORIES
  )
  const pomQuestionCategoriesSanitized = pomQuestionCategories?.filter(isQuestionDifficulty)
  const pomSections = getArrayUrlParam<string>(params, TasksPageParamKey.POM_SECTIONS)
  const pomTopics = getArrayUrlParam<string>(params, TasksPageParamKey.POM_TOPICS)
  const pomQuestions = getArrayUrlParam<string>(params, TasksPageParamKey.POM_QUESTIONS)
  const plant = getUrlParam(params, TasksPageParamKey.PLANT)
  const processStages = getArrayUrlParam<string>(params, TasksPageParamKey.PROCESS_STAGES)
  const equipment = getEquipmentDataUrlParam(params, TasksPageParamKey.EQUIPMENT)
  const mainEquipment = getEquipmentDataUrlParam(params, TasksPageParamKey.MAIN_EQUIPMENT)
  const sortBy = getUrlParam<TasksSortBy>(params, TasksPageParamKey.SORT_BY)
  const sortOrder = getUrlParam<SortOrder>(params, TasksPageParamKey.SORT_ORDER)

  return {
    title,
    dueDateRange,
    statuses: statusesSanitized,
    assignee,
    categories: categoriesSanitized,
    priorities,
    pomActionSources: pomActionSourcesSanitized,
    pomQuestionCategories: pomQuestionCategoriesSanitized,
    pomSections,
    pomTopics,
    pomQuestions,
    plant,
    processStages,
    equipment,
    mainEquipment,
    sortBy: isTasksSortBy(sortBy) ? sortBy : undefined,
    sortOrder: isSortOrder(sortOrder) ? sortOrder : undefined
  }
}

export function tasksPageQueryParamsToUrlParams(
  searchParams: TasksPageQueryParams
): URLSearchParams {
  const params = new URLSearchParams()

  setOrDeleteBooleanParam(params, TasksPageParamKey.ASSIGNED_TO_ME, searchParams.assignedToMe)
  setOrDeleteArrayParam(params, TasksPageParamKey.SOURCES, searchParams.sources)
  setOrDeleteStringParam(params, TasksPageParamKey.TITLE, searchParams.title)
  setOrDeleteStringParam(params, TasksPageParamKey.DUE_DATE_RANGE, searchParams.dueDateRange)
  setOrDeleteStringParam(params, TasksPageParamKey.ASSIGNEE, searchParams.assignee)
  setOrDeleteArrayParam(params, TasksPageParamKey.CATEGORIES, searchParams.categories)
  setOrDeleteArrayParam(params, TasksPageParamKey.STATUSES, searchParams.statuses)
  setOrDeleteArrayParam(params, TasksPageParamKey.PRIORITIES, searchParams.priorities)
  setOrDeleteArrayParam(params, TasksPageParamKey.POM_ACTION_SOURCES, searchParams.pomActionSources)
  setOrDeleteArrayParam(
    params,
    TasksPageParamKey.POM_QUESTION_CATEGORIES,
    searchParams.pomQuestionCategories
  )
  setOrDeleteArrayParam(params, TasksPageParamKey.POM_SECTIONS, searchParams.pomSections)
  setOrDeleteArrayParam(params, TasksPageParamKey.POM_TOPICS, searchParams.pomTopics)
  setOrDeleteArrayParam(params, TasksPageParamKey.POM_QUESTIONS, searchParams.pomQuestions)
  setOrDeleteStringParam(params, TasksPageParamKey.PLANT, searchParams.plant)
  setOrDeleteArrayParam(params, TasksPageParamKey.PROCESS_STAGES, searchParams.processStages)
  setOrDeleteEquipmentDataParam(params, TasksPageParamKey.EQUIPMENT, searchParams.equipment)
  setOrDeleteEquipmentDataParam(
    params,
    TasksPageParamKey.MAIN_EQUIPMENT,
    searchParams.mainEquipment
  )
  setOrDeleteStringParam(params, TasksPageParamKey.SORT_BY, searchParams.sortBy)
  setOrDeleteStringParam(params, TasksPageParamKey.SORT_ORDER, searchParams.sortOrder)

  return params
}

const getEquipmentDataUrlParam = (params: URLSearchParams, key: string) => {
  const equipmentParam = params.get(key)

  return equipmentParam ? parameterToEquipment(equipmentParam) : undefined
}

const setOrDeleteEquipmentDataParam = (
  params: URLSearchParams,
  key: string,
  value?: EquipmentData
) => {
  if (value) {
    params.set(key, equipmentToParameter(value))
  } else {
    params.delete(key)
  }
}

export const isFilterCompatibleWithVisibleFilters = <
  TKeyName extends string,
  TSource extends string
>(
  filterKey: TKeyName,
  visibleFilters: TKeyName[],
  filterCompatibilityConfig: Record<TKeyName, TSource[]>
): boolean => {
  if (!visibleFilters.length) return true

  const compatibilityIntersection = visibleFilters.reduce<TSource[]>((acc, key) => {
    const sources = filterCompatibilityConfig[key] ?? []
    return acc.length ? intersection(acc, sources) : sources
  }, [])
  const filterSources = filterCompatibilityConfig[filterKey] ?? []

  return filterSources.some((source) => compatibilityIntersection.includes(source))
}
