import {Iso8601} from '@hconnect/uikit'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError, AxiosInstance} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {PERFORMANCE_KPI_REQUEST_STALE_TIME} from '../../consts'
import {formatDateUtcToTimezoneDateOnly} from '../../helpers/dateFormat.helpers'
import {
  AlternativeFuelRatesResponse,
  QuarterlyForecastAlternativeFuelRatesHierarchy,
  MtmYtmRequestPayload
} from '../../types'

const getAlternativeFuelRatesMtmYtmUrlSuffix = (showYTM?: boolean) =>
  showYTM ? 'alternative-fuel-rates-ytm' : 'alternative-fuel-rates-mtm'

export const getAlternativeFuelRatesMtmYtmQueryKeys = (requestPayload: MtmYtmRequestPayload) => [
  getAlternativeFuelRatesMtmYtmUrlSuffix(requestPayload.showYTM),
  formatDateUtcToTimezoneDateOnly(requestPayload.timestamp, requestPayload.timezone),
  requestPayload.locationId
]

export const getAlternativeFuelRates = async (
  axiosInstance: AxiosInstance,
  payload: MtmYtmRequestPayload
) => {
  const {timestamp, timezone, locationId} = payload

  const referenceDate = formatDateUtcToTimezoneDateOnly(timestamp, timezone)
  const url = `/kpi/dashboard/${getAlternativeFuelRatesMtmYtmUrlSuffix(payload.showYTM)}`

  const response = await axiosInstance.get<AlternativeFuelRatesResponse>(url, {
    params: {locationId, date: referenceDate}
  })

  return response.data
}

export const useAlternativeFuelRates = <T>(
  payload: MtmYtmRequestPayload,
  options?: UseQueryOptions<AlternativeFuelRatesResponse, AxiosError, T>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const QueryKeys = getAlternativeFuelRatesMtmYtmQueryKeys(payload)

  return useQuery<AlternativeFuelRatesResponse, AxiosError, T>(
    QueryKeys,
    async () => {
      return getAlternativeFuelRates(axiosInstance, payload)
    },
    {
      onError: (err) => handleError(err, navigate),
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      ...options
    }
  )
}

export const useAlternativeFuelRatesQuarterly = (
  timestamp: Iso8601,
  timezone: string,
  locationId?: string,
  options?: UseQueryOptions<QuarterlyForecastAlternativeFuelRatesHierarchy | null, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const referenceDate = formatDateUtcToTimezoneDateOnly(timestamp, timezone)

  return useQuery<QuarterlyForecastAlternativeFuelRatesHierarchy | null, AxiosError>(
    ['alternative-fuel-rates-quarterly', locationId, referenceDate],
    async () => {
      if (!locationId) return Promise.resolve(null)

      const response = await axiosInstance.get<QuarterlyForecastAlternativeFuelRatesHierarchy>(
        '/kpi/dashboard/alternative-fuel-rates-qf',
        {
          params: {locationId, timestamp: referenceDate}
        }
      )
      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      ...options
    }
  )
}
