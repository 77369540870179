import {getTranslationKey} from '@hconnect/common/utils'
import {CardSectionTitle} from '@hconnect/uikit/src/lib2'
import {LinearProgress, Box, Divider, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useHasPermission} from '../../../shared/hooks'
import {CommentPreview} from '../../components'
import {useComments} from '../../hooks'

type CommentsProps = {
  plantId: string
  eventId: string
  timezone: string
  translationPrefix?: string
}

export const ShiftTaskCommentsContainer: React.FC<CommentsProps> = ({
  plantId,
  eventId,
  timezone,
  translationPrefix
}) => {
  const {data: comments, isLoading, isFetching} = useComments({plantId, eventId, timezone})
  const hasPermission = useHasPermission(plantId)
  const {t} = useTranslation()

  return (
    <Box>
      <Box
        sx={{
          mb: 1
        }}
      >
        <CardSectionTitle sx={{mb: 1}} data-test-id="task-comments-title">
          {t(getTranslationKey('shiftEvent.label.comments', translationPrefix))}
          {comments && ` (${comments.length})`}
        </CardSectionTitle>
        {isFetching || isLoading ? (
          <LinearProgress />
        ) : (
          <Divider sx={{borderColor: 'common.black'}} />
        )}
      </Box>
      {comments && (
        <Box my={2}>
          {hasPermission('VIEW_PLANT_EVENT_COMMENTS') && (
            <Stack spacing={2}>
              {comments.map((comment) => (
                <CommentPreview
                  key={comment.id}
                  description={comment.description}
                  creator={comment.creator}
                  createDate={comment.createDate}
                  timezone={timezone}
                  withMarkdown={true}
                  translationPrefix={translationPrefix}
                  dataTestId={`task-comment-item-${comment.id}`}
                />
              ))}
            </Stack>
          )}
        </Box>
      )}
    </Box>
  )
}
