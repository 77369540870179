import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router'

import {ApiPath} from '../../../shared/consts'
import {handleError} from '../../../shared/helpers'
import {useAxios} from '../../../shared/hooks'
import {ShiftTaskDto} from '../../types'

const QueryKey = 'shift-task'

type UseShiftTaskOptions = UseQueryOptions<ShiftTaskDto | undefined, AxiosError>

type Params = {
  plantId: string
  externalId: string
}

export const useShiftTask = ({plantId, externalId}: Params, options?: UseShiftTaskOptions) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const path = `${ApiPath.SHIFT_HANDOVER}/${plantId}/events/${externalId}`

  return useQuery<ShiftTaskDto | undefined, AxiosError>({
    queryKey: [QueryKey, externalId, plantId],
    queryFn: async ({signal}) => {
      const {data} = await axiosInstance.get<ShiftTaskDto>(path, {signal})

      return data
    },
    onError: (err) => handleError(err, navigate),
    ...options
  })
}
