import {EventStatusLabel, Tag} from '@hconnect/common/components'
import {DateFormat, GridSetting} from '@hconnect/uikit'
import {Grid} from '@mui/material'

import {HProduceTask} from '../../types'

import {TaskDescription} from './TaskDescription'

const formatAssignees = (assignees: HProduceTask['assignees']) => {
  return assignees?.map(({name}) => name).join(', ') || '-'
}

const regularSize: GridSetting = {
  xs: 12,
  sm: 4,
  md: 6
}

type Props = {
  task: HProduceTask
  timezone: string
  translationPrefix?: string
}

export const BaseTaskDetails = ({task, translationPrefix, timezone}: Props) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item {...regularSize}>
          <Tag
            labelKey="task.label.status"
            translationPrefix={translationPrefix}
            data-test-id="task-details-status"
          >
            <EventStatusLabel status={task.status} translationPrefix={translationPrefix} />
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="task.label.dueDate"
            data-test-id="task-details-dueDate"
            translationPrefix={translationPrefix}
          >
            <DateFormat date={task.dueDate} timezone={timezone} />
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="task.label.assignee"
            data-test-id="task-details-assignee"
            translationPrefix={translationPrefix}
          >
            {formatAssignees(task.assignees)}
          </Tag>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tag
            labelKey="task.label.description"
            translationPrefix={translationPrefix}
            descriptionSx={{fontWeight: 400, fontSize: 14}}
            data-test-id="task-details-description"
          >
            <TaskDescription description={task.description} />
          </Tag>
        </Grid>
      </Grid>
    </>
  )
}
