import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import React from 'react'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {setAllowedNodes} from '../helpers/hierarchy.helpers'
import {HierarchyNode, HierarchyNodeMetadata} from '../types'

const QueryKey = 'performance-hierarchy'

export const useHierarchy = (options?: UseQueryOptions<HierarchyNodeMetadata, AxiosError>) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<HierarchyNodeMetadata, AxiosError>(
    [QueryKey],
    async () => {
      const response = await axiosInstance.get<HierarchyNodeMetadata>('/kpi/dashboard/hierarchy')

      return response.data
    },
    {
      refetchOnMount: false,
      onError: (err) => handleError(err, navigate),
      ...options,
      onSuccess: (data) => {
        options?.onSuccess?.(data)
      },
      staleTime: Infinity
    }
  )
}

const hierarchyContext = React.createContext<HierarchyNode | undefined>(undefined)

export const HierarchyProvider = ({children}: {children: React.ReactNode}) => {
  const {data: hierarchy, isError, isInitialLoading} = useHierarchy()
  const {allowedPlants, allowedLocations} = useNavAccessContext()

  if (isInitialLoading || isError || !hierarchy) return null

  const allowedHierarchy = setAllowedNodes(hierarchy, allowedPlants.concat(allowedLocations))

  return <hierarchyContext.Provider value={allowedHierarchy}>{children}</hierarchyContext.Provider>
}

// used by consumers
export function useHierarchyData() {
  return React.useContext(hierarchyContext)
}
