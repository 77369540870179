import {EventStatusLabel, Tag} from '@hconnect/common/components'
import {ActionItemDto} from '@hconnect/common/types'
import {getTranslationKey} from '@hconnect/common/utils'
import {DateFormat, GridSetting} from '@hconnect/uikit'
import {Grid} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {TaskDescription} from './TaskDescription'

const regularSize: GridSetting = {
  xs: 12,
  sm: 4,
  md: 6
}

type Props = {
  action: ActionItemDto
  timezone: string
  translationPrefix?: string
}

export const PlantActionDetails = ({action, translationPrefix, timezone}: Props) => {
  const {t} = useTranslation()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item {...regularSize}>
          <Tag
            labelKey="task.label.status"
            translationPrefix={translationPrefix}
            data-test-id="task-details-status"
          >
            <EventStatusLabel status={action.status} translationPrefix={translationPrefix} />
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="task.label.dueDate"
            data-test-id="task-details-dueDate"
            translationPrefix={translationPrefix}
          >
            <DateFormat date={action.dueDate} timezone={timezone} />
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="task.label.assignee"
            data-test-id="task-details-assignee"
            translationPrefix={translationPrefix}
          >
            {action.assignee?.name ?? '-'}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="pomAction.label.source"
            data-test-id="task-details-action-source"
            translationPrefix={translationPrefix}
          >
            {t(getTranslationKey(`pomAction.actionSource.${action.source}`, translationPrefix))}
          </Tag>
        </Grid>
        {action.questionId && (
          <Grid item {...regularSize}>
            <Tag
              labelKey="pomAction.label.connectedTo"
              data-test-id="task-details-connectedTo"
              translationPrefix={translationPrefix}
            >
              {action.questionId}
            </Tag>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tag
            labelKey="task.label.description"
            translationPrefix={translationPrefix}
            descriptionSx={{fontWeight: 400, fontSize: 14}}
            data-test-id="task-details-description"
          >
            <TaskDescription description={action.description} />
          </Tag>
        </Grid>
      </Grid>
    </>
  )
}
