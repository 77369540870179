import {Iso8601, EquipmentExpertSystemUsage} from '@hconnect/common/types'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../shared/hooks/useApi'

const QueryKey = 'config-processStages'

export const useExpertSystemData = (
  plantId: string,
  timeRange: {
    startDate: Iso8601
    endDate: Iso8601
  },
  options?: UseQueryOptions<EquipmentExpertSystemUsage[], AxiosError>
) => {
  const axiosInstance = useAxios()

  return useQuery<EquipmentExpertSystemUsage[], AxiosError>(
    [QueryKey, plantId, timeRange],
    async () => {
      const path = '/kpi/dashboard/expert-system/usage'
      const payload = {
        plantIds: [plantId], // Note: BE supports multiple plants fetch, but we will have one plantId only
        fromDate: timeRange.startDate,
        toDate: timeRange.endDate
      }

      const response = await axiosInstance.post<EquipmentExpertSystemUsage[]>(path, payload)

      return response.data[plantId] || []
    },
    {
      retry: 1,
      ...options
    }
  )
}
