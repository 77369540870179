import {PageContainer} from '@hconnect/uikit/src/lib2'
import {useParams} from 'react-router'

import {DataContentWrapper} from '../components/DataContentWrapper'
import {EquipmentStoppageDetailsForCode} from '../containers/EquipmentStoppageDetailsForCode'
import {usePlantStoppages} from '../hooks/usePlantStoppages'
import {IssueGroupingKey} from '../types'

type PathParameter = {
  plantId: string
  groupId: string
  groupType: IssueGroupingKey.MAIN_EQUIPMENT | IssueGroupingKey.EQUIPMENT_NUMBER
  stoppageCode: string
}

export const DowntimeStoppagesDetailsForCode = () => {
  const {plantId, groupType, groupId, stoppageCode} = useParams<PathParameter>()
  if (!plantId || !groupId || !groupType || !stoppageCode) throw new Error('Missing url parameter')
  if (
    groupType !== IssueGroupingKey.MAIN_EQUIPMENT &&
    groupType !== IssueGroupingKey.EQUIPMENT_NUMBER
  ) {
    throw new Error('Unsuported groupType url parameter')
  }

  const {
    data: stoppageConfig,
    isInitialLoading,
    isError,
    refetch,
    isRefetching
  } = usePlantStoppages(plantId)

  return (
    <PageContainer>
      <DataContentWrapper
        showLoader={isInitialLoading}
        data={stoppageConfig}
        retryFunction={() => void refetch()}
        isError={isError}
        isRefetching={isRefetching}
      >
        <EquipmentStoppageDetailsForCode
          stoppageConfig={stoppageConfig}
          plantId={plantId}
          groupType={groupType}
          groupId={groupId}
          stoppageCode={stoppageCode}
        />
      </DataContentWrapper>
    </PageContainer>
  )
}
