import {generateMarkdownOptions, getHighlightedTitleWithLink} from '@hconnect/common/utils'
import {MarkdownText} from '@hconnect/uikit/src/lib2'

type Props = {
  description: string
  freeSearchText?: string
}

export const TaskDescription = ({description, freeSearchText}: Props) => {
  return (
    <MarkdownText options={generateMarkdownOptions(freeSearchText)}>
      {freeSearchText
        ? getHighlightedTitleWithLink(description, freeSearchText, true)
        : description}
    </MarkdownText>
  )
}
