import {RunningTimeType} from '@hconnect/common/types'

import {LocationType} from './location.types'
import {PerformanceDashboardEquipmentTypes} from './operationHours.types'
import {PlannedYearlyProductionVolumeResponse} from './performance.types'
import {EquipmentRunningTimesResult} from './runningTimes.types'

export type NoDataReason = 'noData' | 'grindingUnitOnly' | 'noPxTrend'

export type PxTrendMetaData = {
  isGrindingUnit: boolean
  productionVolumesAvailable: boolean
  pxTrendAvailable: boolean
  runinngTimesAvailable: boolean
  siloFillLevelsAvailable: boolean
}

export type Plant = {
  plantId: string
  name: string
  lastUpdate?: string
  parentLocationId?: string
  parentLocationType: LocationType
  timezone?: string
  countryCode: string
  countryName: string
  pxTrendMetaData?: PxTrendMetaData
}

export type PlantStatusEquipment = {
  id: string
  name: string
  matchingId: string
  type: PerformanceDashboardEquipmentTypes
  status: RunningTimeType
  productionVolume: number
}

export type PlantEquipmentStatus = {
  plant: Plant // soon deprecated
  equipments: PlantStatusEquipment[]
  actualCementProduction: number // soon deprecated
  actualClinkerProduction: number // soon deprecated
  plantId: string
}

export enum KpisList {
  ClinkerProduction = 'clinkerProduction',
  CementProduction = 'cementProduction',
  KilnReliabilityCoefficient = 'kilnReliabilityCoefficient',
  KilnOperatingCoefficient = 'kilnOperatingCoefficient',
  KilnHeatConsumption = 'kilnHeatConsumption',
  AlternativeFuelRate = 'alternativeFuelRate',
  MTBF = 'meanTimeBetweenFailure',
  TechnicalCementPowerCons = 'technicalCementPowerConsumption'
}

export const optionalKPIs = [
  KpisList.KilnReliabilityCoefficient,
  KpisList.KilnOperatingCoefficient,
  KpisList.KilnHeatConsumption,
  KpisList.AlternativeFuelRate,
  KpisList.MTBF,
  KpisList.TechnicalCementPowerCons
]

type ActualProductionValue = {
  plannedCementProduction: number | null
  plannedClinkerProduction: number | null
  actualCementProduction: number
  actualClinkerProduction: number
  cementDelta: number
  clinkerDelta: number
  plantId: string
  unit: string
}

export type PlantStatusResponse = {
  plant: Plant
  totalCement: number
  totalClinker: number
  unitCement: string
  unitClinker: string
  plannedYearlyProductionVolume: PlannedYearlyProductionVolumeResponse
  actualProductionValue?: ActualProductionValue
  // TODO1: remove running times from here - https://jira.heidelbergcement.com/browse/HCP-74800
  // TODO2: check with HCP-76108 on BE, if type can be changed here to equipmentType, keeping it as is for now as it doesn't interfere
  equipments: PlantStatusEquipment[]
  equipmentRunningTimes?: EquipmentRunningTimesResult
}

export type PlantStatus = {
  plant: Plant
  plannedCementProduction: number
  plannedClinkerProduction: number
  actualCementProduction?: number
  actualClinkerProduction?: number
  totalCement: number
  totalClinker: number
  clinkerDelta?: number
  cementDelta?: number
  unitCement: string
  unitClinker: string
  equipmentRunningTimes?: EquipmentRunningTimesResult
  equipments: PlantStatusEquipment[]
  plannedYearlyProductionVolume: PlannedYearlyProductionVolumeResponse
}

export type ActualAndPlannedData = {
  unit: string
  actual?: number
  planned: number
  delta?: number
}

export interface KPIs {
  alternativeFuelRate: ActualAndPlannedData
  kilnHeatConsumption: ActualAndPlannedData
  kilnReliabilityCoefficient: ActualAndPlannedData
  meanTimeBetweenFailure: ActualAndPlannedData
  kilnOperatingCoefficient: ActualAndPlannedData
  technicalCementPowerConsumption: ActualAndPlannedData
}

export interface KPIsWithProduction extends KPIs {
  clinkerProduction: ActualAndPlannedData
  cementProduction: ActualAndPlannedData
}

export type ProductionActualAndPlannedData = {
  data: ActualAndPlannedData | undefined
  isError: boolean
}

export type PlantKPIs = Partial<
  Record<
    keyof KPIs,
    {
      data: ActualAndPlannedData | undefined
      isError: boolean
    }
  >
>

export type ProductionDataType = {
  unit: string
  planned: number
  actual: number | undefined
  delta?: number
}

export enum KpiEquipmentType {
  Kins = 'kilns'
}
export type EquipmentKpiStats = {
  month: string
  equipmentType: KpiEquipmentType
  unplannedStoppageHours: number
  plannedStoppageHours: number
  incidentCount: number
  circumstanceCount: number
  plannedStoppageCount: number
  runtimeHours: number
  plantId?: string
  equipmentReliabilityCoefficient?: number
  equipmentOperatingCoefficient?: number
  meanTimeBetweenFailures?: number
  productionInTonnes?: number
}
