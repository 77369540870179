import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {createPlantIdMap} from '../mappers'
import {PlantEquipmentStatus, TimeRange} from '../types'

const QueryKey = 'equipment-status'
const PATH = '/kpi/dashboard/plants/equipment-status'
const INTERVAL_PERIOD_IN_MS = 300000

export const useEquipmentStatus = (
  timeRange: TimeRange,
  hierarchyId?: string,
  options?: UseQueryOptions<
    PlantEquipmentStatus[],
    AxiosError,
    Record<string, PlantEquipmentStatus>
  >
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<PlantEquipmentStatus[], AxiosError, Record<string, PlantEquipmentStatus>>(
    [QueryKey, timeRange.startDate, timeRange.endDate, hierarchyId],
    async () => {
      const response = await axiosInstance.get<PlantEquipmentStatus[]>(PATH, {
        params: {
          from: timeRange.startDate,
          to: timeRange.endDate,
          ...(hierarchyId && {hierarchyId})
        }
      })

      return response.data
    },
    {
      refetchInterval: INTERVAL_PERIOD_IN_MS,
      refetchOnMount: false,
      onError: (err) => handleError(err, navigate),
      ...options,
      onSuccess: (data) => {
        options?.onSuccess?.(data)
      },
      select: (data) => createPlantIdMap<PlantEquipmentStatus>(data)
    }
  )
}
