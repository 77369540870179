import {Permission} from '@hconnect/apiclient'
import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {Error403Page, Error404Page} from '@hconnect/uikit/src/lib2'
import {TFunction} from 'i18next'
import React, {FC, useEffect} from 'react'
import {useLocation} from 'react-router'
import {Link as RouterLink, Navigate, Route} from 'react-router-dom'
import {useSessionStorage} from 'react-use'

import {PlantStatusPageReferer, StoppageAnalysisPageReferer} from '../shared/consts'
import {ConfigProviderWrapper, useConfigData} from '../shared/hooks/useConfig'
import {useNavAccessContext} from '../shared/hooks/useNavAccessContext'
import {TranslationPrefixProvider} from '../shared/hooks/useTranslationPrefix'
import {
  PROCESS_ENGINEER_PLANT_KPI,
  PROCESS_ENGINEER_PLANT_KPI_STOPPAGE_ANALYSIS,
  PROCESS_ENGINEER_STOPPAGE_ANALYSIS,
  RELIABILITY_ENGINEER_PLANT_KPI,
  RELIABILITY_ENGINEER_PLANT_KPI_STOPPAGE_ANALYSIS,
  RELIABILITY_ENGINEER_STOPPAGE_ANALYSIS
} from '../shared/routes'
import {Context} from '../shared/types/temp.types'

import {Error500Page} from './components/Error500Page'
import {DefaultShell} from './containers'
import {GlobalPageRedirectWrapper} from './containers/GlobalPageRedirectWrapper'
import {DowntimeAnalyticsEventName, trackAnalyticsEvent} from './helpers'
import {GlobalContextProvider} from './hooks/useGlobalContext'
import {HierarchyProvider} from './hooks/useHierarchy'
import {HProduceToursProvider} from './hooks/useHProduceTours'
import {StoppagesStatsTimeRangeProvider} from './hooks/useStoppagesStatsTimeRange'
import {AreaStatus} from './pages/AreaStatus'
import {DownTimeDashboard} from './pages/DownTimeDashboard'
import {DowntimeStoppagesDetails} from './pages/DowntimeStoppagesDetails'
import {DowntimeStoppagesDetailsForCode} from './pages/DowntimeStoppagesDetailsForCode'
import {DowntimeStoppagesStats} from './pages/DowntimeStoppagesStats'
import {KilnKpiStats} from './pages/KilnKpiStats'
import {KilnMillStatus} from './pages/KilnMillStatus'
import {LocationStatus} from './pages/LocationStatus'
import {NotAvailablePage} from './pages/NotAvailablePage'
import {PerformancePlantSelect} from './pages/PerformancePlantSelect'
import {PerformanceStatusWrapper} from './pages/PerformanceStatusWrapper'
import {PlantStatus} from './pages/PlantStatus'
import {StoppageAnalysis} from './pages/StoppageAnalysis'
import {
  AREA_KPI,
  DOWNTIME,
  ERROR_403,
  ERROR_500,
  GLOBAL_KILN_PERFORMANCE,
  LOCATION_KPI,
  PLANT_DOWNTIME,
  PLANT_DOWNTIME_STOPPAGES,
  PLANT_DOWNTIME_STOPPAGES_DETAILS,
  PLANT_KPI,
  GLOBAL_KILN_STATUS,
  STOPPAGE_ANALYSIS_STATUS,
  UNAUTHORIZED,
  AREA_KPI_STATUS,
  LOCATION_KPI_STATUS,
  PLANT_KPI_STATUS,
  STOPPAGE_ANALYSIS,
  PLANT_PERFORMANCE,
  PLANT_DOWNTIME_STOPPAGES_DETAILS_FOR_CODE,
  PLANT_DOWNTIME_KILN_STATS
} from './routing'

const Wrapper: FC<{context: Context; permissions: Permission[]; children: React.ReactNode}> = ({
  children,
  permissions,
  context
}) => (
  <HProduceToursProvider>
    <GlobalContextProvider initialState={{permissions, ...context}}>
      <HierarchyProvider>
        <TranslationPrefixProvider>
          <DefaultShell context={context}>{children}</DefaultShell>
        </TranslationPrefixProvider>
      </HierarchyProvider>
    </GlobalContextProvider>
  </HProduceToursProvider>
)

const VISITED_DOWNTIME_KEY = 'visitedDowntime'
const DownTimeTrackAnalysisWrapper: FC<{
  context: Context
  children: React.ReactNode
}> = ({children, context}) => {
  const [visitedDowntime, setVisitedDowntime] = useSessionStorage(VISITED_DOWNTIME_KEY, 'false')
  const location = useLocation()
  const {plantId} = useConfigData()

  useEffect(() => {
    if (location.pathname.includes(DOWNTIME) && visitedDowntime === 'false' && plantId) {
      trackAnalyticsEvent(DowntimeAnalyticsEventName.UserEntersDowntimeDashboard, {
        timestamp: new Date().toISOString(),
        plantId,
        plantName: getPlantName(plantId),
        userId: context.user.userId,
        country: context.user.country
      })
      setVisitedDowntime('true')
    }
  }, [
    context.user.country,
    context.user.userId,
    location,
    plantId,
    setVisitedDowntime,
    visitedDowntime
  ])

  return <>{children}</>
}
const LocationGuard: FC<{children: React.ReactNode}> = ({children}) => {
  const {allowedLocations} = useNavAccessContext()

  if (allowedLocations.length === 0) {
    return <PerformancePlantSelect path={PLANT_PERFORMANCE} />
  }
  return <>{children}</>
}

export const getPerformanceDashboardRoutes = (
  context: Context,
  permissions: Permission[],
  t: TFunction
) => {
  return [
    <Route
      key="performance-dashboard-error-403"
      path={ERROR_403}
      element={
        <Wrapper context={context} permissions={permissions}>
          <Error403Page
            caption={t('performanceDashboard.errorPage.accessDenied')}
            description={t('performanceDashboard.errorPage.accessDeniedDescription')}
            onLogout={context.onLogout}
            logoutCaption={t('performanceDashboard.logout')}
          />
        </Wrapper>
      }
    />,
    <Route
      key="performance-dashboard-error-500"
      path={ERROR_500}
      element={
        <Wrapper context={context} permissions={permissions}>
          <Error500Page
            caption={t('performanceDashboard.errorPage.serverError')}
            description={t('performanceDashboard.errorPage.serverErrorDescription')}
            copyLogCaption={t('performanceDashboard.errorPage.serverErrorCopyLog')}
          />
        </Wrapper>
      }
    />,

    /* Performance Routes */
    <Route
      key="performance-dashboard-plant-status"
      path={PLANT_KPI}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <PlantStatus referer={PlantStatusPageReferer.PERFORMANCE} />
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,
    <Route
      key="reliability-engineer-plant-status"
      path={RELIABILITY_ENGINEER_PLANT_KPI}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <PlantStatus referer={PlantStatusPageReferer.RELIABILITY_ENGINEER_DASHBOARD} />
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,
    <Route
      key="process-engineer-plant-status"
      path={PROCESS_ENGINEER_PLANT_KPI}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <PlantStatus referer={PlantStatusPageReferer.PROCESS_ENGINEER_DASHBOARD} />
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,
    <Route
      key="performance-dashboard-global-kiln-performance"
      path={GLOBAL_KILN_PERFORMANCE}
      element={
        <Wrapper context={context} permissions={permissions}>
          <LocationGuard>
            <PerformanceStatusWrapper />
          </LocationGuard>
        </Wrapper>
      }
    />,
    <Route
      key="performance-dashboard-area-kpi"
      path={AREA_KPI}
      element={
        <Wrapper context={context} permissions={permissions}>
          <PerformanceStatusWrapper />
        </Wrapper>
      }
    />,
    <Route
      key="performance-dashboard-location-kpi"
      path={LOCATION_KPI}
      element={
        <Wrapper context={context} permissions={permissions}>
          <PerformanceStatusWrapper />
        </Wrapper>
      }
    />,
    <Route
      key="performance-dashboard-plant-kpi"
      path={PLANT_KPI}
      element={
        <Wrapper context={context} permissions={permissions}>
          <PerformanceStatusWrapper />
        </Wrapper>
      }
    />,
    <Route
      key="status-dashboard-stoppage-analysis"
      path={STOPPAGE_ANALYSIS_STATUS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <StoppageAnalysis referer={StoppageAnalysisPageReferer.STATUS} />
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,
    <Route
      key="performance-dashboard-stoppage-analysis"
      path={STOPPAGE_ANALYSIS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <StoppageAnalysis referer={StoppageAnalysisPageReferer.PERFORMANCE} />
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,
    <Route
      key="process-engineer-dashboard-stoppage-analysis"
      path={PROCESS_ENGINEER_STOPPAGE_ANALYSIS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <StoppageAnalysis referer={StoppageAnalysisPageReferer.PROCESS_ENGINEER_DASHBOARD} />
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,
    <Route
      key="process-engineer-dashboard-plant-status-stoppage-analysis"
      path={PROCESS_ENGINEER_PLANT_KPI_STOPPAGE_ANALYSIS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <StoppageAnalysis referer={StoppageAnalysisPageReferer.PROCESS_ENGINEER_PLANT_STATUS} />
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,
    <Route
      key="reliability-engineer-dashboard-stoppage-analysis"
      path={RELIABILITY_ENGINEER_STOPPAGE_ANALYSIS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <StoppageAnalysis
              referer={StoppageAnalysisPageReferer.RELIABILITY_ENGINEER_DASHBOARD}
            />
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,
    <Route
      key="reliability-engineer-dashboard-plant-status-stoppage-analysis"
      path={RELIABILITY_ENGINEER_PLANT_KPI_STOPPAGE_ANALYSIS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <StoppageAnalysis
              referer={StoppageAnalysisPageReferer.RELIABILITY_ENGINEER_PLANT_STATUS}
            />
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,
    <Route
      key="performance-dashboard-unauthorized"
      path={UNAUTHORIZED}
      element={
        <Wrapper context={context} permissions={permissions}>
          <NotAvailablePage />
        </Wrapper>
      }
    />,

    <Route
      key="performance-dashboard-performance-redirect"
      path={'/'}
      element={<Navigate to={GLOBAL_KILN_PERFORMANCE} />}
    />,

    /* End of Performance Routes */

    /* Status Routes */
    <Route
      key="performance-dashboard-global-kiln-status"
      path={GLOBAL_KILN_STATUS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <LocationGuard>
            <GlobalPageRedirectWrapper>
              <KilnMillStatus />
            </GlobalPageRedirectWrapper>
          </LocationGuard>
        </Wrapper>
      }
    />,
    <Route
      key="performance-dashboard-area-status"
      path={AREA_KPI_STATUS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <AreaStatus />
        </Wrapper>
      }
    />,
    <Route
      key="performance-dashboard-location-status"
      path={LOCATION_KPI_STATUS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <LocationStatus />
        </Wrapper>
      }
    />,
    <Route
      key="performance-dashboard-plant-status"
      path={PLANT_KPI_STATUS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <PlantStatus referer={PlantStatusPageReferer.STATUS} />
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,
    /* End of Status Routes */

    /* Downtime Routes */
    <Route
      key="performance-dashboard-downtime"
      path={DOWNTIME}
      element={
        <Wrapper context={context} permissions={permissions}>
          <PerformancePlantSelect path={PLANT_DOWNTIME} />
        </Wrapper>
      }
    />,

    <Route
      key="performance-dashboard-plant-downtime"
      path={PLANT_DOWNTIME}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <DownTimeTrackAnalysisWrapper context={context}>
              <DownTimeDashboard />
            </DownTimeTrackAnalysisWrapper>
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,

    <Route
      key="performance-dashboard-plant-downtime-kiln-stats"
      path={PLANT_DOWNTIME_KILN_STATS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <DownTimeTrackAnalysisWrapper context={context}>
              <KilnKpiStats />
            </DownTimeTrackAnalysisWrapper>
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,

    <Route
      key="performance-dashboard-plant-downtime-stoppages"
      path={PLANT_DOWNTIME_STOPPAGES}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <DownTimeTrackAnalysisWrapper context={context}>
              <StoppagesStatsTimeRangeProvider>
                <DowntimeStoppagesStats />
              </StoppagesStatsTimeRangeProvider>
            </DownTimeTrackAnalysisWrapper>
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,

    <Route
      key="performance-dashboard-plant-downtime-stoppages-details"
      path={PLANT_DOWNTIME_STOPPAGES_DETAILS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <DownTimeTrackAnalysisWrapper context={context}>
              <StoppagesStatsTimeRangeProvider>
                <DowntimeStoppagesDetails />
              </StoppagesStatsTimeRangeProvider>
            </DownTimeTrackAnalysisWrapper>
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,

    <Route
      key="performance-dashboard-plant-downtime-stoppages-details-for-code"
      path={PLANT_DOWNTIME_STOPPAGES_DETAILS_FOR_CODE}
      element={
        <Wrapper context={context} permissions={permissions}>
          <ConfigProviderWrapper>
            <DownTimeTrackAnalysisWrapper context={context}>
              <DowntimeStoppagesDetailsForCode />
            </DownTimeTrackAnalysisWrapper>
          </ConfigProviderWrapper>
        </Wrapper>
      }
    />,

    /* End of Downtime Routes */

    <Route
      key="performance-dashboard-error-404"
      path={'*'}
      element={
        <Wrapper context={context} permissions={permissions}>
          <Error404Page
            caption={t('performanceDashboard.errorPage.notFound')}
            description={t('performanceDashboard.errorPage.notFoundDescription')}
            goToCaption={t('performanceDashboard.errorPage.goToHome')}
            goToLink={'/'}
            RouterLink={RouterLink}
          />
        </Wrapper>
      }
    />
  ]
}
