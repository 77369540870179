import {PLANT_KPI as PlantKpi} from '@hconnect/common/hproduce/config'

// Base routes
export const PERFORMANCE = '/performance'
export const DOWNTIME = '/downtime'
export const STATUS = '/status'
export const CHECKLISTS = '/checklists'

export const TERMS_AND_CONDITIONS = '/tc'
export const PRIVACY_POLICY = '/privacy'

// Extended shared routes
export const PLANT_KPI_STATUS = `${STATUS}/plant/:plantId`
export const LOCATION_KPI_STATUS = `${STATUS}/location/:locationId`

export const PLANT_KPI = PlantKpi
export const LOCATION_KPI = `${PERFORMANCE}/location/:locationId`

// HROC shared routes
export const RELIABILITY_ENGINEER_DASHBOARD = '/reliability-engineer-dashboard'
export const PLANT_RELIABILITY_ENGINEER_DASHBOARD = `${RELIABILITY_ENGINEER_DASHBOARD}/plant/:plantId`
export const RELIABILITY_ENGINEER_STOPPAGE_ANALYSIS = `${PLANT_KPI}/equipment/:equipmentId/reliability-engineer`
export const RELIABILITY_ENGINEER_PLANT_KPI = `${PLANT_KPI}/reliability-engineer`
export const RELIABILITY_ENGINEER_PLANT_KPI_STOPPAGE_ANALYSIS = `${PLANT_KPI}/equipment/:equipmentId/reliability-engineer-stoppage-analysis`

export const PROCESS_ENGINEER_DASHBOARD = '/process-engineer-dashboard'
export const PLANT_PROCESS_ENGINEER_DASHBOARD = `${PROCESS_ENGINEER_DASHBOARD}/plant/:plantId`
export const PROCESS_ENGINEER_STOPPAGE_ANALYSIS = `${PLANT_KPI}/equipment/:equipmentId/process-engineer`
export const PROCESS_ENGINEER_PLANT_KPI = `${PLANT_KPI}/process-engineer`
export const PROCESS_ENGINEER_PLANT_KPI_STOPPAGE_ANALYSIS = `${PLANT_KPI}/equipment/:equipmentId/process-engineer-stoppage-analysis`

export const OPTIMIZATION_SPECIALIST_DASHBOARD = '/optimization-specialist-dashboard'
export const PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD = `${OPTIMIZATION_SPECIALIST_DASHBOARD}/plant/:plantId`
