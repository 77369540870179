import {useCallback} from 'react'
import {useMatch, useNavigate} from 'react-router-dom'

export const useGoToProductRoute = () => {
  const navigate = useNavigate()
  const params = useMatch('/:appName/:plantId/')?.params
  const {appName, plantId} = params || {}

  return useCallback(
    (_route: string, method: 'push' | 'replace' = 'push') => {
      if (!appName || !plantId) {
        // TBD what should happen if appName & plantId are not present
        // in theory that would only happen for the entry point and the utility pages but never for an app
        console.error(
          'try to route within a product that is not following the /:appName/:plantId/:feature structure'
        )
        return
      }

      // TBD should we force a starting "/" in the route or just add it if its not there
      const route = _route.startsWith('/') ? _route : `/${_route}`
      history[method]?.(`/${appName}/${plantId}${route}`)
      navigate(`/${appName}/${plantId}${route}`, {replace: method === 'replace'})
    },
    [appName, plantId, navigate]
  )
}
