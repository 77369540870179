import {ShiftEventCommentDto} from '@hconnect/common/types'
import {useQuery, type UseQueryOptions} from '@tanstack/react-query'
import {type AxiosError} from 'axios'

import {ApiPath} from '../../../../shared/consts'
import {useAxios} from '../../../../shared/hooks'

export const EventCommentsQueryKey = 'events-comments'

type Params = {
  plantId: string
  eventId: string
  timezone: string
}

export const useComments = (
  {plantId, eventId, timezone}: Params,
  options?: UseQueryOptions<ShiftEventCommentDto[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const path = `${ApiPath.SHIFT_HANDOVER}/${plantId}/events/${eventId}/comments`

  return useQuery<ShiftEventCommentDto[], AxiosError>({
    queryKey: [EventCommentsQueryKey, eventId, plantId, timezone],
    queryFn: async ({signal}) => {
      const response = await axiosInstance.get<ShiftEventCommentDto[]>(path, {signal})
      return response.data
    },
    ...options
  })
}
