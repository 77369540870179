import {OtherRunningTimeTypes} from '@hconnect/common/types'
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead
} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {usePlantEquipmentData} from '../hooks/usePlantsEquipmentStatus'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {PLANT_KPI_STATUS} from '../routing'
import {
  HierarchyNode,
  HierarchyNodeType,
  PerformanceDashboardEquipmentTypes,
  PlantEquipmentStatus,
  PxTrendMetaData
} from '../types'

import {NoDataReasonLabel, ProductionVolumeLabel} from './ProductionVolumeLabel'

type KilnStatusTableProps = {
  plants: HierarchyNode[]
}

enum StatusPageEquipmentType {
  Kiln = 'kiln',
  Mill = 'Mill'
}

function getProductionContent({
  type,
  plantEquipmentStatus,
  pxTrendMetaData
}: {
  type: StatusPageEquipmentType
  plantEquipmentStatus?: PlantEquipmentStatus
  pxTrendMetaData?: PxTrendMetaData
}) {
  const equipmentList = plantEquipmentStatus?.equipments

  // filter equipments for kiln / mill column
  const equipments =
    type === StatusPageEquipmentType.Mill
      ? equipmentList?.filter(
          (equipment) =>
            equipment.type === PerformanceDashboardEquipmentTypes.CEMENT_MILL ||
            equipment.type === PerformanceDashboardEquipmentTypes.RAW_MILL ||
            equipment.type === PerformanceDashboardEquipmentTypes.ROLLER_PRESS
        ) || []
      : equipmentList?.filter(
          (equipment) => equipment.type === PerformanceDashboardEquipmentTypes.KILN
        ) || []

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: 0.5,
        columnGap: 3
      }}
    >
      {equipments.length ? (
        equipments.map(({id, status, name}) => {
          return (
            <ProductionVolumeLabel
              key={id}
              status={status || OtherRunningTimeTypes.UNKNOWN}
              id={id}
              name={name}
            />
          )
        })
      ) : (
        <NoDataReasonLabel
          isMill={type === StatusPageEquipmentType.Mill}
          pxTrendMetaData={pxTrendMetaData}
        />
      )}
    </Box>
  )
}

export const KilnStatusTable: FC<KilnStatusTableProps> = ({plants}) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  const {plantEquipmentStatuses} = usePlantEquipmentData()

  const allowedPlants = React.useMemo(() => plants?.filter((plant) => plant.isAllowed), [plants])

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t(`${performancePrefix}.kilnStatus.label.plant`)}</TableCell>
            <TableCell align="left">{t(`${performancePrefix}.kilnStatus.label.kiln`)}</TableCell>
            <TableCell align="left">{t(`${performancePrefix}.kilnStatus.label.mills`)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allowedPlants?.map(
            ({id, name, type, pxTrendMetaData}, index) =>
              type === HierarchyNodeType.Plant && (
                <TableRow
                  key={id}
                  data-test-id={`kiln-status-table-row-${id}`}
                  onClick={() => navigate(generatePath(PLANT_KPI_STATUS, {plantId: id}))}
                  sx={{
                    cursor: 'pointer'
                  }}
                >
                  <TableCell
                    sx={{
                      paddingX: {xs: 0, sm: 2},
                      width: 150,
                      ...(allowedPlants.length - 1 === index
                        ? {borderBottom: 'none', paddingBottom: 0}
                        : {})
                    }}
                  >
                    <Typography variant="subtitle1">{name}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(allowedPlants.length - 1 === index
                        ? {borderBottom: 'none', paddingBottom: 0}
                        : {})
                    }}
                  >
                    {getProductionContent({
                      type: StatusPageEquipmentType.Kiln,
                      plantEquipmentStatus: plantEquipmentStatuses[id],
                      pxTrendMetaData
                    })}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(allowedPlants.length - 1 === index
                        ? {borderBottom: 'none', paddingBottom: 0}
                        : {})
                    }}
                  >
                    {getProductionContent({
                      type: StatusPageEquipmentType.Mill,
                      plantEquipmentStatus: plantEquipmentStatuses[id],
                      pxTrendMetaData
                    })}
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
