import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {Comment, TimeRange} from '../types'

export const QueryKey = 'comments'

type CommentsPayload = {
  timeRange: TimeRange
  locationId: string
}

export const useComments = (
  {timeRange, locationId}: CommentsPayload,
  options?: UseQueryOptions<Comment[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<Comment[], AxiosError>(
    [QueryKey, timeRange, locationId],
    async () => {
      const url = `/kpi/dashboard/comments/${locationId}`
      const response = await axiosInstance.get<Comment[]>(url, {
        params: {from: timeRange.startDate, to: timeRange.endDate}
      })

      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      staleTime: Infinity,
      ...options
    }
  )
}
