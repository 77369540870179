import {ProcessStage} from '@hconnect/common/types'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'

import {useAxios} from '../../shared/hooks/useApi'

const QueryKey = 'config-processStages'

export const useProcessStages = (
  plantId: string,
  options?: UseQueryOptions<Map<string, string>, AxiosError>
) => {
  const axiosInstance = useAxios()
  const {
    i18n: {language}
  } = useTranslation()

  return useQuery<Map<string, string>, AxiosError>(
    [QueryKey, plantId, language],
    async () => {
      const path = `/plantconfig/${plantId}/processstages`
      const params = {params: {language}}

      const response = await axiosInstance.get<ProcessStage[]>(path, params)

      return new Map(
        response.data.map((stage: ProcessStage) => {
          return [stage.processStep, stage.processStepValue]
        })
      )
    },
    {
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 1,
      ...options
    }
  )
}
