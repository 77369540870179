import {BaseTaskDetails, TaskDetailsCard} from '../../components'
import {HProduceTask, TaskSource} from '../../types'

import {PlantActionDetailsContainer} from './PlantActionDetailsContainer'
import {ShiftTaskDetailsContainer} from './ShiftTaskDetailsContainer'

type Props = {
  task: HProduceTask
  onClose: () => void
  timezone: string
  freeSearchText?: string
  translationPrefix?: string
}

export const TaskDetailsFactory = ({
  task,
  onClose,
  freeSearchText,
  timezone,
  translationPrefix
}: Props) => {
  switch (task.source) {
    case TaskSource.COCKPIT:
      return (
        <ShiftTaskDetailsContainer
          task={task}
          onClose={onClose}
          timezone={timezone}
          translationPrefix={translationPrefix}
        />
      )
    case TaskSource.EASY_POM:
      return (
        <PlantActionDetailsContainer
          task={task}
          onClose={onClose}
          timezone={timezone}
          translationPrefix={translationPrefix}
        />
      )
    default: {
      return (
        <TaskDetailsCard
          data-test-id="page-events-details"
          onClose={onClose}
          item={task}
          freeSearchText={freeSearchText}
          timezone={timezone}
          translationPrefix={translationPrefix}
        >
          <BaseTaskDetails task={task} timezone={timezone} translationPrefix={translationPrefix} />
        </TaskDetailsCard>
      )
    }
  }
}
