import {getTranslationKey} from '@hconnect/common/utils'
import {LoadingButton} from '@mui/lab'
import {Stack, Typography} from '@mui/material'
import {isFunction} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

type ErrorPlaceholderProps = {
  translationPrefix?: string
  retryFunction?: () => void
  isRefetching?: boolean
}

export const ErrorPlaceholder: React.FC<ErrorPlaceholderProps> = ({
  retryFunction,
  isRefetching,
  translationPrefix
}) => {
  const {t} = useTranslation()

  return (
    <Stack spacing={2} alignItems="center">
      <Typography>
        {t(getTranslationKey('error.label.fetchingError', translationPrefix))}
      </Typography>
      {isFunction(retryFunction) && (
        <LoadingButton
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            retryFunction()
          }}
          variant="outlined"
          color="primary"
          data-test-id="error-placeholder-retry-button"
          disabled={isRefetching}
          loading={isRefetching}
        >
          {t(getTranslationKey('error.action.retry', translationPrefix))}
        </LoadingButton>
      )}
    </Stack>
  )
}
