import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {FailureTypesResponse, TimeRange} from '../types'

const QueryKey = 'stoppage-failure-types'

export const useFailureTypes = (
  plantId: string,
  chartPayload: TimeRange,
  yearOffset = 5,
  options?: UseQueryOptions<FailureTypesResponse, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<FailureTypesResponse, AxiosError>(
    [QueryKey, yearOffset],
    async () => {
      const path = `/downtime-dashboard/${plantId}/stoppages/failure-types`
      const response = await axiosInstance.post<FailureTypesResponse>(path, {
        timeRange: chartPayload,
        yearOffset
      })

      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
