import {ConfigContext} from '@hconnect/common/hooks/ConfigContext'
import {mergeSx} from '@hconnect/uikit/src/lib2'
import {CircularProgress} from '@mui/material'
import React, {FC, useContext} from 'react'
import {useParams} from 'react-router'

import {PlaceholderWrapper} from '../components/PlaceholderWrapper'
import {FeatureFlag} from '../helpers/featureFlagHelpers'
import {SimpleErrorPage} from '../pages/SimpleErrorPage'

import {useConfig} from './configService'

const configContext = React.createContext<ConfigContext<FeatureFlag> | undefined>(undefined)

type PathParameter = {
  plantId: string
}
type ProviderProps = {
  initialState: ConfigContext<FeatureFlag>
  children: React.ReactNode
}

export const ConfigProvider = ({initialState, children}: ProviderProps) => (
  <configContext.Provider value={initialState}>{children}</configContext.Provider>
)

export const ConfigProviderWrapper: FC<{children: React.ReactNode}> = ({children}) => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('Missing plantId prop')
  const {data: config, isError, isInitialLoading, error} = useConfig(plantId)

  if (isInitialLoading) {
    return (
      <PlaceholderWrapper noBackground>
        <CircularProgress sx={mergeSx({color: 'common.white'})} />
      </PlaceholderWrapper>
    )
  }

  if (isError) {
    return (
      <SimpleErrorPage>
        <pre>{(error as Error)?.toString?.()}</pre>
      </SimpleErrorPage>
    )
  }

  return config ? <ConfigProvider initialState={config}>{children}</ConfigProvider> : null
}

export function useConfigData() {
  const ctx = useContext(configContext)
  if (ctx === undefined) {
    throw new Error('useConfig used outside of ConfigProvider')
  } else {
    return ctx
  }
}
