import {Iso8601} from '@hconnect/common/types'
import {MarkdownText} from '@hconnect/uikit/src/lib2'
import {Stack} from '@mui/material'
import React from 'react'

import {MetaAnnotation} from './MetaAnnotation'

type CommentPreviewProps = {
  description: string
  creator: string
  createDate: Iso8601
  timezone: string
  withMarkdown?: boolean
  translationPrefix?: string
  dataTestId?: string
}

export const CommentPreview: React.FC<CommentPreviewProps> = ({
  description,
  creator,
  createDate,
  withMarkdown,
  timezone,
  translationPrefix,
  dataTestId
}) => {
  return (
    <Stack sx={{wordBreak: 'break-word', whiteSpace: 'pre-line'}} data-test-id={dataTestId}>
      {withMarkdown ? (
        <MarkdownText>{description}</MarkdownText>
      ) : (
        <>
          {description} <br />
        </>
      )}
      <MetaAnnotation
        userName={creator}
        date={createDate}
        timezone={timezone}
        translationPrefix={translationPrefix}
      />
    </Stack>
  )
}
