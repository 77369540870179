import {DateTimeFormat} from '@hconnect/common/components'
import {getTranslationKey} from '@hconnect/common/utils'
import {Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

type Props = {
  timezone: string
  label?: string
  userName?: string
  date?: string
  translationPrefix?: string
  'data-test-id'?: string
}

export const MetaAnnotation: React.FC<Props> = ({
  label,
  userName,
  date,
  translationPrefix,
  timezone,
  'data-test-id': dataTestId
}) => {
  const {t} = useTranslation()

  return (
    <Typography gutterBottom variant="caption" data-test-id={dataTestId}>
      {label && `${label} `}
      {userName && `${userName} `}
      {date && (
        <>
          {t(getTranslationKey('metaAnnotation.label.happenOn', translationPrefix))}{' '}
          <DateTimeFormat date={date} timezone={timezone} />
        </>
      )}
    </Typography>
  )
}
