import {useExpandedGroups} from '@hconnect/common/hooks/useExpandGroups'
import {PageContainer} from '@hconnect/uikit/src/lib2'
import {Typography} from '@mui/material'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router'

import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {CollapseLocationItem} from '../components/CollapseLocationItem'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {DateTimeRangePicker} from '../components/DateTimeRangePicker'
import {StatusPageHeader} from '../containers/StatusPageHeader'
import {
  findLocationEntry,
  getRegionIdList,
  hasRegionTypeWithChildren
} from '../helpers/location.helpers'
import {useEquipmentStatus} from '../hooks/useEquipmentStatus'
import {useHierarchyData} from '../hooks/useHierarchy'
import {PlantEquipmentDataProvider} from '../hooks/usePlantsEquipmentStatus'
import {useStatusInitialTimeRange} from '../hooks/useStatusInitialTimeRange'
import {useTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {STATUS} from '../routing'
import {HierarchyNode, TimeRange} from '../types'

type PathParameter = {
  locationId: string
}

export const AreaStatus: React.FC = () => {
  const {locationId} = useParams<PathParameter>()
  if (!locationId) throw new Error('Missing locationId prop')

  const {isLocationAllowed, allowMultipleLocations, getRedirectStatusURL} = useNavAccessContext()

  const navigate = useNavigate()
  useEffect(() => {
    if (!isLocationAllowed(locationId)) {
      const redirectUrl = getRedirectStatusURL()
      redirectUrl && navigate(redirectUrl)
    }
  }, [isLocationAllowed, locationId, navigate, getRedirectStatusURL])

  const {t} = useTranslation()
  const {from, to} = useTimeRange()
  const dateRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}

  const timeRangeLoaded = useStatusInitialTimeRange('status')
  const {
    data: equipmentStatusData = {},
    refetch: equipmentStatusRefetch,
    isInitialLoading: equipmentStatusIsInitialLoading,
    isError: isEquipmentStatusError,
    isRefetching: isEquipmentStatusRefetching
  } = useEquipmentStatus(dateRange, locationId, {enabled: timeRangeLoaded})

  const hierarchy = useHierarchyData()

  const node = hierarchy ? findLocationEntry(hierarchy, locationId) : undefined

  const regionList: string[] = useMemo(
    () => getRegionIdList(locationId, hierarchy),
    [hierarchy, locationId]
  )

  useEffect(() => {
    if (!equipmentStatusIsInitialLoading && expandedAreas.length !== regionList?.length)
      toggleExpandedAreas('all')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentStatusIsInitialLoading])

  const showWithRegion = useMemo(() => {
    return hierarchy ? hasRegionTypeWithChildren(hierarchy) : undefined
  }, [hierarchy])

  const {performancePrefix} = useTranslationPrefix()

  const [expandedAreas, toggleExpandedAreas] = useExpandedGroups(regionList, 'area-status-expanded')

  return (
    <PageContainer>
      <StatusPageHeader
        filterComponent={<DateTimeRangePicker type="status" disableCustomSelect />}
        title={node?.name}
        backButtonProps={
          allowMultipleLocations()
            ? {
                targetName: t(`${performancePrefix}.goBackLabels.backToGlobal`),
                target: STATUS
              }
            : undefined
        }
      />
      <DataContentWrapper
        showLoader={equipmentStatusIsInitialLoading || equipmentStatusIsInitialLoading}
        data={equipmentStatusData}
        retryFunction={() => {
          if (isEquipmentStatusError) void equipmentStatusRefetch()
        }}
        isError={isEquipmentStatusError}
        isRefetching={isEquipmentStatusRefetching}
      >
        <Typography variant="h2" sx={{fontSize: 24, mb: 2, mt: 3, ml: 3}}>
          {t(`${performancePrefix}.locationStatus.label.plants`)}
        </Typography>

        <PlantEquipmentDataProvider plantEquipmentStatuses={equipmentStatusData}>
          {showWithRegion && (
            <>
              {hierarchy &&
                findLocationEntry(hierarchy, locationId)?.children.map(
                  (hierarchyNode: HierarchyNode, index: number) => (
                    <CollapseLocationItem
                      key={`kiln-status-collapse-item-${index}`}
                      handleOpen={toggleExpandedAreas}
                      isOpen={expandedAreas.includes(hierarchyNode.id)}
                      index={index}
                      location={hierarchyNode}
                      hideDrillDownButton={true}
                    />
                  )
                )}
            </>
          )}
        </PlantEquipmentDataProvider>
      </DataContentWrapper>
    </PageContainer>
  )
}
