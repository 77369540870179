import {User} from '@hconnect/common/types'
import {useDebounce} from '@react-hook/debounce'

import {FilterSearchAutocomplete, FilterSearchAutocompleteProps} from '../../../shared/components'
import {SEARCH_DELAY_IN_MS} from '../../../shared/consts'
import {useAssigneeSearch, useUserById} from '../../hooks'

export const AssigneeSearchFilter = <TName extends string>({
  value,
  ...otherProps
}: Omit<
  FilterSearchAutocompleteProps<TName, string>,
  'options' | 'getOptionLabel' | 'getOptionValue' | 'isOptionEqualToValue' | 'searchTerm'
>) => {
  const [debouncedSearchText, setDebouncedSearchTerm] = useDebounce('', SEARCH_DELAY_IN_MS)
  const {data: suggestions = [], isFetching} = useAssigneeSearch(debouncedSearchText, {
    enabled: !!debouncedSearchText
  })

  const {data: selectedUser} = useUserById(value!, {
    enabled: !!value
  })

  return (
    <FilterSearchAutocomplete<TName, User>
      {...otherProps}
      value={selectedUser}
      onChange={(name, user) => otherProps.onChange(name, user?.id)}
      options={suggestions}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      searchTerm={debouncedSearchText}
      onSearchChange={setDebouncedSearchTerm}
      isLoading={isFetching}
    />
  )
}
